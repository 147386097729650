import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../Button/ButtonContainer';
import styled from '@emotion/styled';
import colors from '../../../../helpers/colors';

const SectionPublicitaire = styled.section`
  margin-bottom: 20px;
  padding: 40px;
  background: url(form_bandeau.svg) no-repeat center center scroll;
  background-size: cover;
`
// const TextPub = styled.h4`
//   color: ${colors.white};
//   font-family: 'Avenir';
//   font-weight: 700;
//   font-size: 1.5em;
//   margin: 10px 0;
// `
const TextPubBis = styled.h4`
  color: ${colors.white};
  font-family: 'Avenir';
  font-weight: 700;
  font-size: 2em;
  margin: 0;
`


const StagePub = () => (
  <SectionPublicitaire className="col-md-offset-1 col-md-10 col-xs-12"> 
  <div className="row middle-md">
    <div className="col-md-offset-1 col-md-8 col-xs-12 start-md center-xs">
      <TextPubBis style={{marginBottom: 5}}>Stage vacances scolaires bientôt disponible</TextPubBis>
      {/* <TextPub >Venez découvrir les activités disponibles</TextPub> */}
    </div>
    <div className="col-md-2 col-xs-12 middle-md center-xs">
      <Link to="/register" style={{ textDecoration: 'none'}}>
          <Button 
            color={colors.white}
            background={colors.primary_alt}
            border="none"
            text="Je participe"
          />
        </Link>
    </div>
  </div>
  </SectionPublicitaire>
);

export default StagePub;
