import React, { Component } from 'react';
import Stage from './Stage';

class StageContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount(){
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <Stage/>
        );
    }
}

export default StageContainer;
