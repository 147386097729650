import React, { Component } from "react";
import { Form, Field } from "react-final-form";
import { Redirect } from "react-router";
import Helmet from "react-helmet";
import Headers from "../../Header/HeaderContainer";
import Footer from "../../Footer/FooterContainer";
import ErrorWithDelay from "../Authentification/ErrorWithDelay";
import StylesRegister from "./StylesRegister";
import api from "../../../helpers/api";

class ModifProfil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registerOK: false,
      data:[],
      formInit:[],
      errorSubmit: []
    };
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    const token = JSON.parse(localStorage.getItem('client_conciergerie')).jwt;
        if(!(localStorage.getItem('info_client'))){
            this.setState({
                error: "Information client introuvable réessayer ou contacter nous"
            })
        } else {
            const id = JSON.parse(localStorage.getItem('info_client')) ? JSON.parse(localStorage.getItem('info_client')).user.infoclient : "undefined";
            const infoclient = await api.getInfoById(id, token);

            if(infoclient.statusCode === 404 || infoclient.statusCode === 401){
                this.setState({
                    error: infoclient.error
                })
            } else {
                const FormInitValues = {
                  "nom" : infoclient.nom,
                  "prenom" : infoclient.prenom,
                  "telephone" : infoclient.telephone,
                  "adresse" : infoclient.adresse,
                  "postal": infoclient.postal,
                  "nom_contact": infoclient.contactsupps[0].nom,
                  "prenom_contact": infoclient.contactsupps[0].prenom,
                  "telephone_contact": infoclient.contactsupps[0].telephone,
                  "enfant": infoclient.enfants.length,
                  "prenom_enfant_1": infoclient.enfants[0] ? infoclient.enfants[0].prenom : "",
                  "nom_enfant_1": infoclient.enfants[0] ? infoclient.enfants[0].nom: "",
                  "ecole_enfant_1": infoclient.enfants[0] ? infoclient.enfants[0].ecole: "",
                  "birthday_enfant_1": infoclient.enfants[0] ?  infoclient.enfants[0].birthday: "",
                  "nom_enfant_2": infoclient.enfants[1] ? infoclient.enfants[1].nom: "",
                  "prenom_enfant_2": infoclient.enfants[1] ?  infoclient.enfants[1].prenom: "",
                  "ecole_enfant_2": infoclient.enfants[1] ?  infoclient.enfants[1].ecole: "",
                  "birthday_enfant_2":infoclient.enfants[1] ?  infoclient.enfants[1].birthday: "",
                  "nom_enfant_3":infoclient.enfants[2] ?  infoclient.enfants[2].nom: "",
                  "prenom_enfant_3":infoclient.enfants[2] ?  infoclient.enfants[2].prenom: "",
                  "ecole_enfant_3":infoclient.enfants[2] ?  infoclient.enfants[2].ecole: "",
                  "birthday_enfant_3":infoclient.enfants[2] ?  infoclient.enfants[2].birthday: "",
                  "nom_enfant_4":infoclient.enfants[3] ?  infoclient.enfants[3].nom: "",
                  "prenom_enfant_4":infoclient.enfants[3] ?  infoclient.enfants[3].prenom: "",
                  "ecole_enfant_4":infoclient.enfants[3] ?  infoclient.enfants[3].ecole: "",
                  "birthday_enfant_4":infoclient.enfants[3] ?  infoclient.enfants[3].birthday: "",
                 }
                this.setState({
                    formInit: FormInitValues,
                    data: infoclient
                })

            }
        }
        console.log("state:",this.state);
  }
  onSubmit = async values => {
    const token = JSON.parse(localStorage.getItem('client_conciergerie')).jwt;
    console.log("VALUES ONSUBMIT", values);
    const infoClient = await {
      genre: values.civilite,
      nom: values.nom.trim(),
      prenom: values.prenom.trim(),
      telephone: values.telephone.trim(),
      adresse: values.adresse,
      postal: values.postal.trim(),
    };
    const resInfoClient = await api.updateInfoClient(infoClient, token, this.state.data.id)

    if(values.enfant > 0){
      if(values.prenom_enfant_1){
          let infoEnfant1 = await {
              prenom: values.prenom_enfant_1.trim(),
              nom: values.nom_enfant_1.trim(),
              birthday: values.birthday_enfant_1.trim(),
              ecole: values.ecole_enfant_1.trim(),
          }
          const resInfoEnfant = await api.updateInfoEnfant(infoEnfant1, token, this.state.data.enfants[0].id);
          console.log("INFO ENFANT : ",resInfoEnfant);
      }
      if(values.prenom_enfant_2) {
          let infoEnfant2 = await {
              prenom: values.prenom_enfant_2.trim(),
              nom: values.nom_enfant_2.trim(),
              birthday: values.birthday_enfant_2.trim(),
              ecole: values.ecole_enfant_2.trim(),
          }
          const resInfoEnfant = await api.updateInfoEnfant(infoEnfant2, token, this.state.data.enfants[1].id);
          console.log("INFO ENFANT : ",resInfoEnfant);
      }
      if(values.prenom_enfant_3) {
          let infoEnfant3 = await {
              prenom: values.prenom_enfant_3.trim(),
              nom: values.nom_enfant_3.trim(),
              birthday: values.birthday_enfant_3.trim(),
              ecole: values.ecole_enfant_3.trim(),
          }
          const resInfoEnfant = await api.updateInfoEnfant(infoEnfant3, token, this.state.data.enfants[2].id);
          console.log("INFO ENFANT : ",resInfoEnfant);
      }
      if(values.prenom_enfant_4) {
          let infoEnfant4 = await {
              prenom: values.prenom_enfant_4.trim(),
              nom: values.nom_enfant_4.trim(),
              birthday: values.birthday_enfant_4.trim(),
              ecole: values.ecole_enfant_4.trim(),
          }
          const resInfoEnfant = await api.updateInfoEnfant(infoEnfant4, token, this.state.data.enfants[3].id);
          console.log("INFO ENFANT : ",resInfoEnfant);
      }
      if(values.prenom_enfant_5) {
          let infoEnfant5 = await {
              prenom: values.prenom_enfant_5.trim(),
              nom: values.nom_enfant_5.trim(),
              birthday: values.birthday_enfant_5.trim(),
              ecole: values.ecole_enfant_5.trim(),
          }
          const resInfoEnfant = await api.updateInfoEnfant(infoEnfant5, token, this.state.data.enfants[4].id);
          console.log("INFO ENFANT : ",resInfoEnfant);
      }
  }
    const infoContact = await {
      nom: values.nom_contact.trim(),
      prenom: values.prenom_contact.trim(),
      telephone: values.telephone_contact.trim(),
      role: values.role,
    };
    const resInfoContact = await api.updateInfoContact(infoContact, token, this.state.data.contactsupps[0].id)
    if(resInfoClient && resInfoContact){
      console.log("Modification mamen --> ",resInfoClient,resInfoContact);
      if(resInfoClient.statusCode === 400){
          return(resInfoClient.message);
      }   
      if(resInfoContact.statusCode === 400){
        return(resInfoContact.message);
      }  
      else{
        this.setState({registerOK: true});
        // localStorage.setItem('client_conciergerie',JSON.stringify(resRegister));
        // localStorage.setItem('info_client',JSON.stringify(resInfoClient));
        return(resInfoClient);
    }
  }

  };


  createFieldEnfants = values => {
    let res = [];
    for (let i = 1; i <= values; i++) {
      let prenom = "prenom_enfant_" + i;
      let nom = "nom_enfant_" + i;
      let birthday = "birthday_enfant_" + i;
      let ecole = "ecole_enfant_" + i;
      res.push(
        <div className="col-xs-12" key={i}>
          <div className="row">
            <Field name={prenom} validate={this.required}>
              {({ input, meta }) => (
                <div className="col-xs-6">
                  <label>Prenom</label>
                  <input {...input} type="text" placeholder="" />
                  <ErrorWithDelay name={prenom}>
                    {error => <span>{error}</span>}
                  </ErrorWithDelay>
                </div>
              )}
            </Field>
            <Field name={nom} validate={this.required}>
              {({ input, meta }) => (
                <div className="col-xs-6">
                  <label>Nom</label>
                  <input {...input} type="text" placeholder="" />
                  <ErrorWithDelay name={nom}>
                    {error => <span>{error}</span>}
                  </ErrorWithDelay>
                </div>
              )}
            </Field>
            <Field name={birthday} validate={this.required}>
              {({ input, meta }) => (
                <div className="col-md-6 col-xs-12">
                  <label>Date de naissance</label>
                  <input {...input} type="date" placeholder="" />
                  <ErrorWithDelay name={birthday} delay={8000}>
                    {error => <span>{error}</span>}
                  </ErrorWithDelay>
                </div>
              )}
            </Field>
            <Field name={ecole} validate={this.required}>
              {({ input, meta }) => (
                <div className="col-xs-6">
                  <label>Nom de l'école</label>
                  <input {...input} type="text" placeholder="" />
                  <ErrorWithDelay name={ecole}>
                    {error => <span>{error}</span>}
                  </ErrorWithDelay>
                </div>
              )}
            </Field>
            <hr />
          </div>
        </div>
      );
    }
    return res;
  };
  //TEST REQUIRED, VALIDATE EMAIL
  required = value => (value ? undefined : "Champs requis");
  validateEmail = value => {
    var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(value).toLowerCase())) {
      return "Mauvais format email";
    }
  };
  validateTelephone = value => {
    var re = /^(01|02|03|04|05|06|08)[0-9]{8}$/;
    if (!re.test(String(value).toLowerCase())) {
      return "Mauvais format telephone ex:0601020304";
    }
  };
  validatePostal = value => {
    var re = /^([0-9]{5})$/;
    if (!re.test(String(value).toLowerCase())) {
      return "Mauvais format du code postal (ex: Paris 75000)";
    }
  };
  validatePassword = value => {
    var re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if (!re.test(String(value).toLowerCase())) {
      return "Mauvais format de mot de passe (8 caractère minimun et un chiffre minimun)";
    }
  };
  validateUsername = value => {
    var re = /^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/;
    if (!re.test(String(value).toLowerCase())) {
      return "Mauvais format pour le username (Espace interdit)";
    }
  };
  validateAge = value => {
    var re = /^([0-9]{2})$/;
    if (!re.test(String(value).toLowerCase())) {
      return "Mauvais format pour le username (Espace interdit)";
    }
  };
  //
  composeValidators = (...validators) => value =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

  render() {
    console.log(this.state);

    if (this.state.registerOK === true) {
      // localStorage.setItem('client_conciergerie',JSON.stringify(res.message));
      // c = localStorage.getItem('client_conciergerie')
      // console.log(JSON.parse(c))
      return <Redirect to="/" />;
    } else {
      // const dataCli = this.onSubmit()
      // console.log("Render dataCli --> ", dataCli);
      return (
        <div className="row">
          <Helmet>
            <title>Modification des informations du profil || La Conciergeries des Petits</title>
            <meta name="keywords" content="HTML,CSS,JavaScript" />
            <meta name="author" content="Adrien Gillmann" />
            <meta
              name="description"
              content="Modification des informations du profil"
            />
          </Helmet>
          <Headers />
          <div className="col-xs-12">
            <StylesRegister className="row">
              <div className="col-xs-12 ">
                <h1 className="center-xs">Modification des informations du profil</h1>
                <div className="col-md-offset-2 col-md-8 col-xs-12">
                  <Form
                    onSubmit={this.onSubmit}
                    initialValues={this.state.formInit }
                    render={({
                      handleSubmit,
                      form,
                      submitting,
                      pristine,
                      values
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <p>Modification des informations générales</p>
                        <div className="row">
                          <label className="col-xs-12">Civilite</label>
                          <div className="col-md-2 col-xs-6">
                            <label>
                              <Field
                                name="civilite"
                                component="input"
                                type="radio"
                                value="Madame"
                                validate={this.required}
                              />{" "}
                              Madame
                            </label>
                          </div>
                          <div className="col-md-2 col-xs-6">
                            <label>
                              <Field
                                name="civilite"
                                component="input"
                                type="radio"
                                value="Monsieur"
                                validate={this.required}
                              />{" "}
                              Monsieur
                            </label>
                          </div>
                          <ErrorWithDelay name="civilite" delay={8000}>
                            {error => <span>{error}</span>}
                          </ErrorWithDelay>
                        </div>
                        <div className="row">
                          <Field name="nom" validate={this.required}>
                            {({ input, meta }) => (
                              <div className="col-md-6 col-xs-12">
                                <label>Nom</label>
                                <input {...input} type="text" placeholder="" />
                                <ErrorWithDelay name="nom" delay={8000}>
                                  {error => <span>{error}</span>}
                                </ErrorWithDelay>
                              </div>
                            )}
                          </Field>
                          <Field name="prenom" validate={this.required}>
                            {({ input, meta }) => (
                              <div className="col-md-6 col-xs-12">
                                <label>Prenom</label>
                                <input {...input} type="text" placeholder="" />
                                <ErrorWithDelay name="prenom" delay={8000}>
                                  {error => <span>{error}</span>}
                                </ErrorWithDelay>
                              </div>
                            )}
                          </Field>
                          <Field
                            name="telephone"
                            validate={this.composeValidators(
                              this.required,
                              this.validateTelephone
                            )}
                          >
                            {({ input, meta }) => (
                              <div className="col-md-6 col-xs-12">
                                <label>Telephone mobile</label>
                                <input {...input} type="text" placeholder="" />
                                <ErrorWithDelay name="telephone" delay={8000}>
                                  {error => <span>{error}</span>}
                                </ErrorWithDelay>
                              </div>
                            )}
                          </Field>
                        </div>

                        {/* INFOCOMPLEMENTAIRE */}
                        <p>Modification des informations complémentaires</p>
                        <div className="row">
                          <Field name="adresse" validate={this.required}>
                            {({ input, meta }) => (
                              <div className="col-xs-12">
                                <label>Adresse</label>
                                <input {...input} type="text" placeholder="" />
                                <ErrorWithDelay name="adresse" delay={8000}>
                                  {error => <span>{error}</span>}
                                </ErrorWithDelay>
                              </div>
                            )}
                          </Field>
                          <Field
                            name="postal"
                            validate={this.composeValidators(
                              this.required,
                              this.validatePostal
                            )}
                          >
                            {({ input, meta }) => (
                              <div className="col-xs-12">
                                <label>Code postal</label>
                                <input {...input} type="text" placeholder="" />
                                <ErrorWithDelay name="postal" delay={8000}>
                                  {error => <span>{error}</span>}
                                </ErrorWithDelay>
                              </div>
                            )}
                          </Field>
                          <p>Modification des informations des enfants</p>
                          {values.enfant > 0 &&
                            this.createFieldEnfants(values.enfant)}

                        </div>
                        {/* INFOCONTACT */}
                        <p>Modification du contact</p>
                        <div className="row">
                          <Field name="nom_contact">
                            {({ input, meta }) => (
                              <div className="col-md-6 col-xs-12">
                                <label>Nom</label>
                                <input {...input} type="text" placeholder="" />
                                <ErrorWithDelay name="nom_contact" delay={8000}>
                                  {error => <span>{error}</span>}
                                </ErrorWithDelay>
                              </div>
                            )}
                          </Field>
                          <Field name="prenom_contact">
                            {({ input, meta }) => (
                              <div className="col-md-6 col-xs-12">
                                <label>Prenom</label>
                                <input {...input} type="text" placeholder="" />
                                <ErrorWithDelay
                                  name="prenom_contact"
                                  delay={8000}
                                >
                                  {error => <span>{error}</span>}
                                </ErrorWithDelay>
                              </div>
                            )}
                          </Field>
                          <Field name="role">
                            {({ input, meta }) => (
                              <div className="col-md-6 col-xs-12">
                                <label>Quel est sont rôle ?</label>
                                <select {...input}>
                                  <option value="Parent de l'enfant" selected>
                                    Parents de l'enfant
                                  </option>
                                  <option value="Grand-parent ou proche">
                                    Grand-parent ou proche
                                  </option>
                                  <option value="École">École</option>
                                  <option value="Autre">Autre</option>
                                </select>
                                <ErrorWithDelay name="role" delay={8000}>
                                  {error => <span>{error}</span>}
                                </ErrorWithDelay>
                              </div>
                            )}
                          </Field>
                          <Field name="telephone_contact">
                            {({ input, meta }) => (
                              <div className="col-md-6 col-xs-12">
                                <label>Son numéro de téléphone</label>
                                <input {...input} type="text" placeholder="" />
                                <ErrorWithDelay
                                  name="telephone_contact"
                                  delay={8000}
                                >
                                  {error => <span>{error}</span>}
                                </ErrorWithDelay>
                              </div>
                            )}
                          </Field>
                          {/* <p>Modification de l'abonnement</p>
                          <Field name="statut">
                            {({ input, meta }) => (
                              <div className="col-xs-12">
                                <label />
                                <select {...input}>
                                  <option value="Non-Membre">Non-Membre</option>
                                  <option value="Infinite">Infinite</option>
                                  <option value="Platinium">Platinium</option>
                                </select>
                                <ErrorWithDelay name="role" delay={8000}>
                                  {error => <span>{error}</span>}
                                </ErrorWithDelay>
                              </div>
                            )}
                          </Field> */}
                        </div>
                        <div className="row">
                                <div className="col-xs-offset-3 col-xs-6 center-xs">
                                    <button type="submit"  disabled={submitting}>
                                        <span>Modifier</span>
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 center-xs">
                                    <span>{this.state.errorSubmit ? this.state.errorSubmit.message : []}</span>
                                </div>
                            </div>
                            {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                      </form>
                    )}
                  />
                </div>
              </div>
            </StylesRegister>
          </div>
          <Footer />
        </div>
      );
    }
  }
}

export default ModifProfil;
