import React, { Component } from 'react';
import Page404 from './Page404';

class Page404Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prevLocationPath: "",
        };
    }
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    render() {
        console.log(this.state);
        return (
            <Page404/>
        );
    }
}

export default Page404Container;
