class colors {
    primary = "#00717F";
    primary_alt = "#1090A0";
    primary_bis = "#B9DDDF";
    blue = "#5C87A4";
    blue_link = "#35A7DE";
    blue_alt = "#D2EDFF";
    blue_ter = "#3DABDF"; 
    blue_bis = "#D0F0FD";
    black = "#424242";
    grey = "#AEB1BA";
    grey_bis="#F3F3F3";
    grey_alt = "#F4F8FF";
    white = "#FFFFFF";
    white_alt = "#F2F2F2";
    orange = "#D5775D";
    orange_alt = "#FFE1D9";

}

export default new colors();