import styled from '@emotion/styled';

import colors from '../../../../helpers/colors';

export default styled.div`
    h1 {
      color: ${colors.primary};
      font-family: 'Avenir';
      font-weight: 900;
      font-size: 2.5em;
      margin-top: 170px;
      @media (max-width: 1023px){
        margin-top: 50px;
      }
    }
    h2 {
      color: ${colors.primary_alt};
      font-family: 'Avenir';
      font-weight: 300;
      font-size: 2.5em;
      margin: 0px 0px;
    }

    p {
      color: ${colors.black};
      font-family: 'Avenir';
      font-weight: 600;
      font-size: 1.2em;
      margin: 30px 0px;
    }
    label {
      display: block;
      color: ${colors.black};
      font-family: 'Avenir';
      font-weight: 300;
      font-size: 1em;
      margin: 10px 0px;
      & > [type=radio]{
        color: red;
      }
    }
    input {
      font-family: 'Avenir';
      font-weight: 300;
      font-size: 1em;
      box-sizing: border-box;
      width: 100%;
      padding:10px 15px; 
      &[type='ratio']{
        display: inline-block;
      }
      &:focus{
        border: 2px solid ${colors.primary_alt};
      }
    }
    input[type=radio] {
      height:15px;
      width:40px;
    }
    select {
      font-family: 'Avenir';
      font-weight: 300;
      font-size: 1em;
      padding:10px 15px; 
      box-sizing: border-box;
      width: 90%;
      background: ${colors.grey_bis};
      color: ${colors.black}
    }
    span {
      display block;
      font-family: 'Avenir';
      font-weight: 300;
      font-size: 0.80em;
      margin-top: 5px;
      color:red;
    }
    
    button {
      color: ${colors.white};
      background: ${colors.primary};
      border: none;
      border-radius: 34px;
      padding: 13px 23px;
      margin-top: 50px;
      margin-bottom: 80px;
      width: 70%; 
      transition: 0.33s;
      cursor:pointer;
      &:hover {
        position:relative;
        top: -2px;
      }
      & > span {
        font-family: 'Avenir';
        font-weight: 700;
        letter-spacing: 0.1em;
        font-size: 1em;
        color: ${colors.white};
      }
      
`