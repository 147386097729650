import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import colors from '../../../../helpers/colors';

const SectionInformation = styled.section`
  margin-bottom: 40px;
`

const TextInfo = styled.h4`
  color: ${colors.black};
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 2em;
  margin: 10px 0;
`

const TextInfoBis = styled.h5`
  color: ${colors.black};
  font-family: 'Avenir';
  font-weight: bold;
  font-size: 1.5em;
  margin: 10px 0;
`

const TextLink = styled.h5`
  color: ${colors.primary_alt};
  font-family: 'Avenir';
  font-weight: bold;
  font-size: 1.5em;
  margin: 10px 0;
  text-decoration: underline ${colors.primary_alt};
  @media (max-width: 1023px){
    font-size: 1.3em;
  }
`

const Information = () => (
    <SectionInformation className="col-xs-12 center-xs">
        <TextInfo><span style={{fontWeight: "bold"}}>Besoin d'informations ? </span>N'hésitez pas à nous contacter</TextInfo>
        <div style={{marginTop: "20px"}} className="row">
            <div className="col-md-offset-3 col-md-3 col-xs-6">
                <TextInfoBis>Par téléphone</TextInfoBis>
                <a href="tel:+33664590515"><TextLink>06 64 59 05 15</TextLink></a>
            </div>
            <div className="col-md-3 col-xs-6">
                <TextInfoBis>Par Message</TextInfoBis>
                <Link to="/contact" style={{ textDecoration: 'none'}}>
                    <TextLink>Formulaire de contact</TextLink>
                </Link>
            </div>
        </div>
  </SectionInformation>
);

export default Information;
