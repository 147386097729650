import React, {Component} from 'react';
import Helmet from 'react-helmet';

import Header from '../../Header/HeaderContainer';
import Footer from '../../Footer/FooterContainer';
import styled from '@emotion/styled';
import colors from '../../../helpers/colors';

const Button = styled.button`
    color: ${colors.white};
    border-radius: 34px;
    margin-bottom: 50px;
    padding: 13px 23px;
    border: none;
    background: ${colors.primary_alt};
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);
    transition: 0.33s;s

    &:after {
        box-shadow: 0 1px 2px rgba(0,0,0,0.15);
        
    }
    
    /* Transition to showing the bigger shadow on hover */
    &:hover {
        box-shadow: 0 4px 9px rgba(0,0,0,0.3);
        position:relative;
        top: -2px;
    }
    box-shadow: 8px 10px 8px -9px #777;
    cursor:pointer;
    font-family: 'Avenir';
    font-weight: 700;
    letter-spacing: 0.1em;
    font-size: 1.5em;
`

const TitleContent = styled.div`
    margin-top: 150px;
    @media (max-width: 1023px) {
        margin-top: 0px;
    }

`
const Title = styled.h1`
    font-family: 'Avenir';
    font-weight: 600;
    font-size: 2.5em; 
    color: ${colors.black};
    margin: 0;
`

const TitleBis = styled.h2`
    font-family: 'Avenir';
    font-weight: 300;
    font-size: 2em; 
    color: ${colors.black};
    margin: 10px 0 20px;
`
const Text = styled.h3`
    font-family: 'Avenir';
    font-weight: 300;
    font-size: 1.5em; 
    color: ${colors.black};
    margin: 10px 0 20px;
`

const Input = styled.input`
    font-family: 'Avenir';
    font-weight: 300;
    font-size: 1em;
    box-sizing: border-box;
    width: 100%;
    padding:10px 15px;
    &:focus{
        border: 2px solid ${colors.primary_alt};
    }
`

const Textarea = styled.textarea`
    font-family: 'Avenir';
    font-weight: 300;
    font-size: 1em;
    box-sizing: border-box;
    width: 100%;
    padding:10px 0px 10px 15px; 
    &:focus{
        border: 2px solid ${colors.primary_alt};
    }
`

const FormContent = styled.div`
    padding: 0;
    margin: 15px 0;
`

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            particulier: false,
            profesionnel: false 
        };
    }
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    particulier = () => {
        this.setState({particulier: true, profesionnel: false});
    }
    profesionnel = () => {
        this.setState({profesionnel: true, particulier: false});
    }
    render() {
        console.log(this.state)
        return (
            <div className="row">
            <Helmet>
                <title>Contact || La Conciergeries des Petits</title>
                <meta name="keywords" content="HTML,CSS,JavaScript"/>
                <meta name="author" content="Adrien Gillmann"/>
                <meta
                    name="description"
                    content="Formulaire de contact"  
                />
            </Helmet>
            <Header/>
                <TitleContent className="col-xs-12">
                    <Title className="center-xs">Contactez nous</Title>
                    <TitleBis className="center-xs">Vous êtes</TitleBis>
                </TitleContent>
                <div className="col-md-offset-2 col-md-8 col-xs-12">
                    <div className="row ">
                        <div className="col-xs-6 end-xs">
                            <Button style={{marginRight: 25}} onClick={this.particulier}>Particulier</Button>
                        </div>
                        <div className="col-xs-6">
                            <Button onClick={this.profesionnel}>Professionnel</Button>
                        </div>
                    </div>
                    {this.state.particulier && (
                        <form className="row" name="message-particulier" action="/" method="post" netlify>
                            <Text className="col-xs-12">Vous êtes un particulier</Text>
                            <Input type="hidden" name="form-name" value="message-particulier"/>
                            <FormContent className="col-xs-6">
                                <Input style={{width:"95%"}} type="text" name="nom" id="nom" placeholder="Nom *" required/>
                            </FormContent>
                            <FormContent className="col-xs-6">
                                <Input type="text" name="prenom" id="prenom" placeholder="Prénom *" required/>
                            </FormContent>
                            <FormContent className="col-xs-12">
                                <Input type="email"  name="email" id="email" placeholder="E-mail *" required/>
                            </FormContent>
                            <FormContent className="col-xs-12">
                                <Textarea  name="msg" id="msg" rows="10" placeholder="Message *" required></Textarea>
                            </FormContent>
                            <div className="col-xs-12 center-xs">
                                <Button style={{ fontSize: "1em"}} type="submit">Envoyer ma demande</Button>
                            </div>
                        </form>
                    )}
                    {this.state.profesionnel && (
                        <form className="row" name="message-professionnel" action="/" method="post" netlify>
                            <Text className="col-xs-12">Vous êtes un profesionnel</Text>
                            <Input type="hidden" name="form-name" value="message-professionnel"/>
                            <FormContent className="col-xs-6">
                                <Input style={{width:"95%"}} type="text" name="nom" id="nom" placeholder="Nom *" required/>
                            </FormContent>
                            <FormContent className="col-xs-6">
                                <Input type="text" name="prenom" id="prenom" placeholder="Prénom *" required/>
                            </FormContent>
                            <FormContent className="col-xs-6">
                                <Input style={{width:"95%"}} type="text" name="societe" id="societe" placeholder="Nom de votre société *" required/>
                            </FormContent>
                            <FormContent className="col-xs-6">
                                <Input type="text" name="activite" id="activite" placeholder="Secteur d'activité *" required/>
                            </FormContent>
                            <FormContent className="col-xs-12">
                                <Input type="email"  name="email" id="email" placeholder="E-mail *" required/>
                            </FormContent>
                            <FormContent className="col-xs-12">
                                <Textarea  name="msg" id="msg" rows="10" placeholder="Message *" required></Textarea>
                            </FormContent>
                            <div className="col-xs-12 center-xs">
                                <Button style={{ fontSize: "1em"}} type="submit">Envoyer ma demande</Button>
                            </div>
                        </form>
                    )}
                    
                </div>
                <Footer/>
          </div>
        );
    }
}


export default Contact;