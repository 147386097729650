import React, { Component } from 'react';
import { Form, Field} from 'react-final-form';
import { Redirect } from 'react-router';
import Helmet from 'react-helmet';
import Headers from '../../../Header/HeaderContainer';
import Footer from '../../../Footer/FooterContainer';
import ErrorWithDelay from '../ErrorWithDelay';
import StylesRegister from './StylesRegister';
import api from '../../../../helpers/api';

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            registerOK: false,
            errorSubmit: []
        };
    }
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    onSubmit = async values => {
        console.log("VALUES ONSUBMIT",values);

        const loginValues = await {
            username: values.username.trim(),
            password: values.password.trim(),
            email: values.email.trim()
        }
        
        const resRegister = await api.postRegister(loginValues);
        console.log("INFO USER : ",resRegister);
        if(resRegister.statusCode === 400){
            this.setState({errorSubmit: resRegister});
            return(resRegister.message);
        }

        const infoClientValues = await {
            genre: values.civilite,
            nom: values.nom.trim(),
            prenom: values.prenom.trim(),
            birthday: values.birthday,
            telephone: values.telephone.trim(),
            adresse:values.adresse.trim(),
            postal: values.postal.trim(),
            statut: values.statut,
            user: resRegister.user._id
        }
        
        const resInfoClient = await api.postInfoClient(infoClientValues);
        console.log("INFO CLIENT : ",resInfoClient);

        if(values.enfant > 0){
            if(values.prenom_enfant_1){
                let infoEnfant1 = await {
                    prenom: values.prenom_enfant_1.trim(),
                    nom: values.nom_enfant_1.trim(),
                    birthday: values.birthday_enfant_1.trim(),
                    ecole: values.ecole_enfant_1.trim(),
                    infoclient: resInfoClient._id
                }
                const resInfoEnfant = await api.postInfoEnfant(infoEnfant1);
                console.log("INFO ENFANT : ",resInfoEnfant);
            }
            if(values.prenom_enfant_2) {
                let infoEnfant2 = await {
                    prenom: values.prenom_enfant_2.trim(),
                    nom: values.nom_enfant_2.trim(),
                    birthday: values.birthday_enfant_2.trim(),
                    ecole: values.ecole_enfant_2.trim(),
                    infoclient: resInfoClient._id
                }
                const resInfoEnfant = await api.postInfoEnfant(infoEnfant2);
                console.log("INFO ENFANT : ",resInfoEnfant);
            }
            if(values.prenom_enfant_3) {
                let infoEnfant3 = await {
                    prenom: values.prenom_enfant_3.trim(),
                    nom: values.nom_enfant_3.trim(),
                    birthday: values.birthday_enfant_3.trim(),
                    ecole: values.ecole_enfant_3.trim(),
                    infoclient: resInfoClient._id
                }
                const resInfoEnfant = await api.postInfoEnfant(infoEnfant3);
                console.log("INFO ENFANT : ",resInfoEnfant);
            }
            if(values.prenom_enfant_4) {
                let infoEnfant4 = await {
                    prenom: values.prenom_enfant_4.trim(),
                    nom: values.nom_enfant_4.trim(),
                    birthday: values.birthday_enfant_4.trim(),
                    ecole: values.ecole_enfant_4.trim(),
                    infoclient: resInfoClient._id
                }
                const resInfoEnfant = await api.postInfoEnfant(infoEnfant4);
                console.log("INFO ENFANT : ",resInfoEnfant);
            }
            if(values.prenom_enfant_5) {
                let infoEnfant5 = await {
                    prenom: values.prenom_enfant_5.trim(),
                    nom: values.nom_enfant_5.trim(),
                    birthday: values.birthday_enfant_5.trim(),
                    ecole: values.ecole_enfant_5.trim(),
                    infoclient: resInfoClient._id
                }
                const resInfoEnfant = await api.postInfoEnfant(infoEnfant5);
                console.log("INFO ENFANT : ",resInfoEnfant);
            }
        }

        const infoContact = await {
            nom: values.nom_contact.trim(),
            prenom: values.prenom_contact.trim(),
            telephone: values.telephone_contact.trim(),
            role: values.role,
            infoclient: resInfoClient._id
        }
        const resInfoContact = await api.postInfoContact(infoContact);
        console.log("INFO CONTACT : ", resInfoContact);

        console.log("RESINFOCLIENT : ",resInfoClient);
        if(resRegister){
            console.log("Register onsubmit --> ",resRegister);
            if(resRegister.user){
                this.setState({registerOK: true});
                // localStorage.setItem('client_conciergerie',JSON.stringify(resRegister));
                // localStorage.setItem('info_client',JSON.stringify(resInfoClient));
                return(resRegister);
            }
            if(resRegister.statusCode === 400){
                return(resRegister.message);
            }   
        }
    }

    createFieldEnfants = (values) => {
        let res = [];
        for(let i=1;i<=values;i++){
            let prenom="prenom_enfant_" + i; 
            let nom="nom_enfant_" + i; 
            let birthday="birthday_enfant_" + i;
            let ecole="ecole_enfant_" + i;
            
            res.push(
            <div className="col-xs-12" key={i}>
                <div className="row">
                    <Field name={prenom} validate={this.required}>
                    {({ input, meta }) => (
                    <div className="col-xs-6">
                        <label>Prenom</label>
                        <input {...input} type="text" placeholder="" />
                        <ErrorWithDelay name={prenom}>
                            {error => <span>{error}</span>}
                        </ErrorWithDelay>
                    </div>
                    )}
                    </Field>
                    <Field name={nom} validate={this.required}>
                    {({ input, meta }) => (
                    <div className="col-xs-6">
                        <label>Nom</label>
                        <input {...input} type="text" placeholder="" />
                        <ErrorWithDelay name={nom}>
                            {error => <span>{error}</span>}
                        </ErrorWithDelay>
                    </div>
                    )}
                    </Field>
                    <Field name={birthday} validate={this.required}>
                    {({ input, meta }) => (
                    <div className="col-md-6 col-xs-12">
                        <label>Date de naissance</label>
                        <input {...input} type="date" placeholder="" />
                        <ErrorWithDelay name={birthday}  delay={8000}>
                            {error => <span>{error}</span>}
                        </ErrorWithDelay>
                    </div>
                    )}
                    </Field>
                    <Field name={ecole} validate={this.required}>
                    {({ input, meta }) => (
                    <div className="col-xs-6">
                        <label>Nom de l'école</label>
                        <input {...input} type="text" placeholder="" />
                        <ErrorWithDelay name={ecole}>
                            {error => <span>{error}</span>}
                        </ErrorWithDelay>
                    </div>
                    )}
                    </Field>
                    <hr/>
                </div>
            </div>
            );
        }
        return res
    }
    //TEST REQUIRED, VALIDATE EMAIL
    required = value => (value ? undefined : "Champs requis");
    validateEmail = (value) => {
        var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!re.test(String(value).toLowerCase())){
            return "Mauvais format email";
        }
    }
    validateTelephone = (value) => {
        var re = /^(01|02|03|04|05|06|08)[0-9]{8}$/;
        if(!re.test(String(value).toLowerCase())){
            return "Mauvais format telephone ex:0601020304";
        }
    }
    validatePostal = (value) => {
        var re = /^([0-9]{5})$/;
        if(!re.test(String(value).toLowerCase())){
            return "Mauvais format du code postal (ex: Paris 75000)";
        }
    }
    validatePassword = (value) => {
        var re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        if(!re.test(String(value).toLowerCase())){
            return "Mauvais format de mot de passe (8 caractère minimun et un chiffre minimun)";
        }
    }
    validateUsername = (value) => {
        var re = /^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/;
        if(!re.test(String(value).toLowerCase())){
            return "Mauvais format pour le username (Espace interdit)";
        }
    }
    validateAge = (value) => {
        var re = /^([0-9]{2})$/;
        if(!re.test(String(value).toLowerCase())){
            return "Mauvais format pour le username (Espace interdit)";
        }
    }
    //
    composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);

    render() {
        console.log(this.state);
        console.log("query :",this.props.location.search);
        let statutParam = this.props.location.search.split("=")
        let abonnement = statutParam[1];
        console.log(abonnement);
        if(this.state.registerOK === true){
            // localStorage.setItem('client_conciergerie',JSON.stringify(res.message));
            // c = localStorage.getItem('client_conciergerie')
            // console.log(JSON.parse(c))
            return(
                <Redirect to="/"/>
            )            
        } else {
            // const dataCli = this.onSubmit()
            // console.log("Render dataCli --> ", dataCli);
            return (
                <div className="row">
                <Helmet>
                    <title>Devenir membre || La Conciergeries des Petits</title>
                    <meta name="keywords" content="HTML,CSS,JavaScript"/>
                    <meta name="author" content="Adrien Gillmann"/>
                    <meta
                        name="description"
                        content="Formulaire pour devenir membre de la conciergeries des petits"  
                    />
                </Helmet>
                     <Headers/>
                <div className="col-xs-12">
                <StylesRegister className="row">
                    <div className="col-xs-12 ">
                        <h1 className="center-xs">Devenir Membre</h1>
                        <h2 className="center-xs">S'inscrire chez La Conciergerie des Petits</h2>
                    <div className="col-md-offset-2 col-md-8 col-xs-12">
                        <Form
                        initialValues={{enfant: 0, statut: this.props.location.search ? abonnement : "Non-Membre"}}
                        onSubmit={this.onSubmit}
                        // initialValues={{ firstName: 'Bob' }}
                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form onSubmit={handleSubmit}>
                           
                            {/* INFOCONNEXION */}
                            <p>Identifiant de connexion</p>
                            <div className="row">
                                <Field name="username" validate={this.composeValidators(this.required, this.validateUsername)}>
                                    {({ input, meta }) => (
                                    <div className="col-xs-12">
                                        <label>Nom d'utilisateur</label>
                                        <input {...input} type="text" placeholder="" />
                                        <ErrorWithDelay name="username" delay={8000}>
                                            {error => <span>{error}</span>}
                                        </ErrorWithDelay>
                                    </div>
                                    )}
                                </Field>
                                <Field name="email" validate={this.composeValidators(this.required, this.validateEmail)}>
                                    {({ input, meta }) => (
                                    <div className="col-xs-12">
                                        <label>E-Mail</label>
                                        <input {...input} type="email" placeholder="" />
                                        <ErrorWithDelay name="email" delay={8000}>
                                            {error => <span>{error}</span>}
                                        </ErrorWithDelay>
                                    </div>
                                    )}
                                </Field>
                                <Field name="password" validate={this.composeValidators(this.required, this.validatePassword)}>
                                    {({ input, meta }) => (
                                    <div className="col-xs-12">
                                        <label>Mot de passe</label>
                                        <input {...input} type="password" placeholder="" />
                                        <ErrorWithDelay name="password" delay={8000}>
                                            {error => <span>{error}</span>}
                                        </ErrorWithDelay>
                                    </div>
                                    )}
                                </Field>
                            </div>
                            {/* INFOGENERAL */}
                            
                            <p>Informations générales</p>
                            <div className="row">
                                <label className="col-xs-12">Civilite</label>
                                <div className="col-md-2 col-xs-6">
                                    <label>
                                    <Field name="civilite" component="input" type="radio" value="Madame" validate={this.required}/>{' '}
                                        Madame
                                    </label>
                                </div>
                                <div className="col-md-2 col-xs-6">
                                    <label>
                                        <Field name="civilite" component="input" type="radio" value="Monsieur" validate={this.required}/>{' '}
                                        Monsieur
                                    </label>
                                </div>
                                <ErrorWithDelay name="civilite" delay={8000}>
                                    {error => <span>{error}</span>}
                                </ErrorWithDelay>
                            </div>
                            <div className="row">
                                <Field name="nom" validate={this.required}>
                                    {({ input, meta }) => (
                                    <div className="col-md-6 col-xs-12">
                                        <label>Nom</label>
                                        <input {...input} type="text" placeholder="" />
                                        <ErrorWithDelay name="nom" delay={8000}>
                                            {error => <span>{error}</span>}
                                        </ErrorWithDelay>
                                    </div>
                                    )}
                                </Field>
                                <Field name="prenom" validate={this.required}>
                                    {({ input, meta }) => (
                                    <div className="col-md-6 col-xs-12">
                                        <label>Prenom</label>
                                        <input {...input} type="text" placeholder="" />
                                        <ErrorWithDelay name="prenom" delay={8000}>
                                            {error => <span>{error}</span>}
                                        </ErrorWithDelay>
                                    </div>
                                    )}
                                </Field>
                                <Field name="telephone" validate={this.composeValidators(this.required, this.validateTelephone)}>
                                    {({ input, meta }) => (
                                    <div className="col-md-6 col-xs-12">
                                        <label>Telephone mobile</label>
                                        <input {...input} type="text" placeholder="" />
                                        <ErrorWithDelay name="telephone" delay={8000}>
                                            {error => <span>{error}</span>}
                                        </ErrorWithDelay>
                                    </div>
                                    )}
                                </Field>
                            </div>
                           
                            {/* INFOCOMPLEMENTAIRE */}
                            <p>Informations complémentaires</p>
                            <div className="row">
                                <Field name="adresse" validate={this.required}>
                                    {({ input, meta }) => (
                                    <div className="col-xs-12">
                                        <label>Adresse</label>
                                        <input {...input} type="text" placeholder="" />
                                        <ErrorWithDelay name="adresse" delay={8000}>
                                            {error => <span>{error}</span>}
                                        </ErrorWithDelay>
                                    </div>
                                    )}
                                </Field>
                                <Field name="postal" validate={this.composeValidators(this.required, this.validatePostal)}>
                                    {({ input, meta }) => (
                                    <div className="col-xs-12">
                                        <label>Code postal</label>
                                        <input {...input} type="text" placeholder="" />
                                        <ErrorWithDelay name="postal" delay={8000}>
                                            {error => <span>{error}</span>}
                                        </ErrorWithDelay>
                                    </div>
                                    )}
                                </Field>
                                <Field name="enfant">
                                    {({ input, meta }) => (
                                    <div className="col-xs-12">
                                        <label>Enfants</label>
                                        <select {...input} >
                                            <option value="0" selected>0 enfant</option>
                                            <option value="1">1 enfant</option>
                                            <option value="2">2 enfants</option>
                                            <option value="3">3 enfants</option>
                                            <option value="4">4 enfants</option>
                                            <option value="5">5 enfants</option>
                                        </select>
                                        <ErrorWithDelay name="enfant" delay={8000}>
                                            {error => <span>{error}</span>}
                                        </ErrorWithDelay>
                                    </div>
                                    )}    
                                </Field>
                                {values.enfant > 0 && (
                                    this.createFieldEnfants(values.enfant)
                                )}
                            </div>
                            {/* INFOCONTACT */}
                            <p>Un parent, un proche, une école à contacter ?</p>
                            <div className="row">
                                <Field name="nom_contact">
                                    {({ input, meta }) => (
                                    <div className="col-md-6 col-xs-12">
                                        <label>Nom</label>
                                        <input {...input} type="text" placeholder="" />
                                        <ErrorWithDelay name="nom_contact" delay={8000}>
                                            {error => <span>{error}</span>}
                                        </ErrorWithDelay>
                                    </div>
                                    )}
                                </Field>
                                <Field name="prenom_contact">
                                    {({ input, meta }) => (
                                    <div className="col-md-6 col-xs-12">
                                        <label>Prenom</label>
                                        <input {...input} type="text" placeholder="" />
                                        <ErrorWithDelay name="prenom_contact" delay={8000}>
                                            {error => <span>{error}</span>}
                                        </ErrorWithDelay>
                                    </div>
                                    )}
                                </Field>
                                <Field name="role">
                                    {({ input, meta }) => (
                                    <div className="col-md-6 col-xs-12">
                                        <label>Quel est son rôle ?</label>
                                        <select {...input} >
                                            <option value="Parent de l'enfant" selected>Parents de l'enfant</option>
                                            <option value="Grand-parent ou proche">Grand-parent ou proche</option>
                                            <option value="École">École</option>
                                            <option value="Autre">Autre</option>
                                        </select>
                                        <ErrorWithDelay name="role" delay={8000}>
                                            {error => <span>{error}</span>}
                                        </ErrorWithDelay>
                                    </div>
                                    )}    
                                </Field>
                                <Field name="telephone_contact">
                                    {({ input, meta }) => (
                                    <div className="col-md-6 col-xs-12">
                                        <label>Son numéro de téléphone</label>
                                        <input {...input} type="text" placeholder="" />
                                        <ErrorWithDelay name="telephone_contact" delay={8000}>
                                            {error => <span>{error}</span>}
                                        </ErrorWithDelay>
                                    </div>
                                    )}
                                </Field>
                                <p>Choix de l'abonnement</p>
                                <Field name="statut">
                                    {({ input, meta }) => (
                                    <div className="col-xs-12">
                                        <label></label>
                                        <select {...input} >
                                            <option value="Non-Membre">Non-Membre</option>
                                            <option value="Infinite">Infinite</option>
                                            <option value="Platinium">Platinium</option>
                                        </select>
                                        <ErrorWithDelay name="role" delay={8000}>
                                            {error => <span>{error}</span>}
                                        </ErrorWithDelay>
                                    </div>
                                    )}    
                                </Field>
                            </div>
                            <div className="row">
                                <div className="col-xs-offset-3 col-xs-6 center-xs">
                                    <button type="submit"  disabled={submitting}>
                                        <span>Enregistrer</span>
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 center-xs">
                                    <span>{this.state.errorSubmit ? this.state.errorSubmit.message : []}</span>
                                </div>
                            </div>
                            {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                        </form>
                        )}
                        />
                        </div>
                    </div>
                </StylesRegister> 
                </div>
                <Footer/>
                </div>
            );
        }
    }
}

export default Register;