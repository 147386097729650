import React, { Component } from 'react';
import Sportive from './Sportive';
import api from '../../../../../helpers/api';

class SportiveContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSportive: [],
        };
    }

    async componentDidMount(){
        window.scrollTo(0, 0);
        const dataSportive = await api.getServiceEnfance("sportive");

        this.setState({
            dataSportive: dataSportive.services,
        })
        console.log("state:",this.state);
    }
    render() {
        return (
            <Sportive
                dataSportive={this.state.dataSportive}
            />
        );
    }
}

export default SportiveContainer;
