// Lib React
import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import Header from '../../Header/HeaderContainer';
import Footer from '../../Footer/FooterContainer';
import StagePub from '../Sections/StagePub/StagePub';
import Abonnements from '../../Cards/Abonnements/AbonnementContainer';
import Information from '../Sections/Information/Information';

//import api from '../../../helpers/api';
// Lib Styles
import styled from '@emotion/styled';
import colors from '../../../helpers/colors';
//import EnConstruction from '../../EnConstruction';

//////////////////// STYLES

const SectionQuestion = styled.section`
    margin-bottom: 20px;
`

const QuestionContent = styled.div`
  padding: 0px 70px;
`

const SectionAbonnement = styled.section`
    
  margin-top: 140px;
  @media (max-width: 1023px){
    margin-top: 0px;
  }
`

const SectionPublicitaire = styled.section`
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 40px;
  background: url(form_bandeau.svg) no-repeat center center scroll;
  background-size: cover;
`

////////// TYPO

const TitleBis = styled.h2`
  color: ${colors.primary};
  font-family: 'Avenir';
  font-weight: 700;
  font-size: 2em;
  margin: 50px 0px;
`

const SubTitle = styled.h3`
  color: ${colors.primary_alt};
  font-family: 'Avenir';
  font-weight: 700;
  font-size: 1.5em;
  margin: 30px 0px;
`

const Paragraphe = styled.p`
  color: ${colors.black};
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 1em;
`
const TextPub = styled.h4`
  color: ${colors.white};
  font-family: 'Avenir';
  font-weight: 700;
  font-size: 1.5em;
  margin: 10px 0;
`

const TextPubBis = styled.h4`
  color: ${colors.white};
  font-family: 'Avenir';
  font-weight: 700;
  font-size: 2em;
  margin: 0;
`

//////////////////// VIEW

const Abonnement = ({data}) => (
  <div className="row">
    <Helmet>
        <title>Abonnement || La Conciergeries des Petits</title>
        <meta name="keywords" content="HTML,CSS,JavaScript"/>
        <meta name="author" content="Adrien Gillmann"/>
        <meta
            name="description"
            content="Les formules d'abonnement proposé par la Conciergeries des Petits"  
        />
    </Helmet>
    <Header
      background={colors.primary_alt}
      textColor={colors.white}
      logoColor="blanc"
    />
   <SectionAbonnement className="col-xs-12 center-xs">
      <div className="row center-xs">
        {/* <EnConstruction/> */}
        {/* <Title className="col-sm-12 center-xs">Nos abonnements</Title> */}
        <Abonnements/>
        <Paragraphe style={{marginTop: "10px",fontWeight:"bold"}} className="col-md-12 center-md">* Du lundi au vendredi</Paragraphe>
      </div>
    </SectionAbonnement>
    <SectionPublicitaire className="col-md-offset-1 col-md-10 col-xs-12"> 
    <div className="row">
          <div className="col-md-offset-2 col-md-4 col-xs-12 start-md center-xs">
            <TextPub style={{marginBottom: 5}}>{data ? data.text_gauche_haut : "Offre test"}</TextPub>
            <TextPubBis>{data ? data.text_gauche_bas : "Famille nombreuse"}</TextPubBis>
          </div>
          <div className="col-md-6 col-xs-12 start-md center-xs">
            <TextPub>{data ? data.text_droit_haut : "Famille nombreuse"}</TextPub>
            <TextPub>{data ? data.text_droit_bas : "1 prestations de babysitting (4h) offerte !"}</TextPub>
          </div>
        </div>
    </SectionPublicitaire>
    <SectionQuestion className="col-md-offset-1 col-md-10 col-xs-12">
        <div className="row">
            <TitleBis className="col-xs-12 center-xs">Quelques questions fréquentes</TitleBis>
            <QuestionContent className="col-md-4">
                <SubTitle>Comment contacter mon concierge ?</SubTitle>
                <Paragraphe>Une fois membre, nous vous communiquons le numéro de votre concierge privé. Vous pouvez désormais le contacter par téléphone, sms et WhatsApp.</Paragraphe>
            </QuestionContent>
            <QuestionContent className="col-md-4">
                <SubTitle>Quelles sont mes options de paiement ?</SubTitle>
                <Paragraphe>Nous vous permettons de régler en carte bancaire sur le site internet ou par téléphone, à la carte, mensuellement ou annuellement</Paragraphe>
            </QuestionContent>
            <QuestionContent className="col-md-4">
                <SubTitle>Puis-je résilier mon abonnement ?</SubTitle>
                <Paragraphe>Vous pouvez résilier votre abonnement mensuel à chaque fin de mois, avec un mois de préavis. L'abonnement annuel, une fois réglé, est utilisable pour une année et non résiliable en cours de validité.</Paragraphe>
            </QuestionContent>
        </div>
    </SectionQuestion>
    <Information/>
    <StagePub/>
    <Footer/>

  </div>
);

export default Abonnement;
