import React from 'react';
import { Helmet } from 'react-helmet';

import Loader from 'react-loader-spinner';
import Header from '../../../Header/HeaderContainer';
import CardService from '../../../Cards/CardService/CardService';
import Information from '../../Sections/Information/Information';
import Footer from '../../../Footer/FooterContainer';

import styled from '@emotion/styled';
import colors from '../../../../helpers/colors';
//import EnConstruction from '../../../EnConstruction';

const Title = styled.h1`
    color: ${colors.primary_alt};
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 2.2rem;
    margin-bottom: 0;
    & > span {
        font-weight: 500;
        font-size: 0.85em;
    }
`

// const TitleContent = styled.div`
//     margin-top: 150px;
//     @media (max-width: 1023px){
//         margin-top: 0px;
//     }
// `
const ServiceContent = styled.div`
    margin-top: 150px;
    @media (max-width: 1023px){
        margin-top: 0px;
    }
`

const ServiceEnfance = ({data}) => (
    <div className="row">
        <Helmet>
            <title>Services aux parents || La Conciergeries des Petits</title>
            <meta name="keywords" content="HTML,CSS,JavaScript"/>
            <meta name="author" content="Adrien Gillmann"/>
            <meta
                name="description"
                content="Les services destinés aux parents proposé par la Conciergeries des Petits"  
            />
        </Helmet>
        <Header
            background={colors.primary_alt}
            textColor={colors.white}
            logoColor="blanc"
        />
        {/* <TitleContent className="col-xs-12">
            <div className="row">
                <Title className="col-xs-offset-1 col-xs-10 start-sm center-xs">Services aux parents : <span>Pour vous facilitez la vie !</span></Title>
            </div>
        </TitleContent> */}
        <ServiceContent className="col-xs-12">
            <section className="row" id="">
                {/* <EnConstruction/> */}
                <Title className="col-xs-12 center-xs">Services aux parents</Title>
                <div className="col-md-offset-1 col-md-10">
                    <div className="row">
                    {data && data.length > 0 
                        ? (
                        data.map(service => (
                        <div className="col-md-4 col-sm-6 col-xs-12" key={service.id}>
                            <CardService
                                id={service.id} 
                                key={service.id}
                                name={service.name}
                                description={service.description}
                                capacite={service.capacite}
                                duree={service.duree}
                                prix_m={service.prix_m}
                                prix_p={service.prix_p}
                                illustration={service.illustration.url}
                            />
                        </div>
                        )))
                        : (
                            <div className="col-xs-12 center-xs" style={{marginTop: 80, marginBottom: 50}}>
                                <Loader 
                                    type="Oval"
                                    color="#10909F"
                                    height="35"	
                                    width="35"
                                /> 
                            </div>
                            
                        )
                    }
                    </div>
                </div> 
            </section>
        </ServiceContent>
        <Information/>
        <Footer/>
    </div>
    
);

export default ServiceEnfance;
