import React from 'react';
import { Helmet } from 'react-helmet';
import Loader from 'react-loader-spinner';

import Header from '../../../../Header/HeaderContainer';
import CardService from '../../../../Cards/CardService/CardService';
import Information from '../../../Sections/Information/Information';
import Footer from '../../../../Footer/FooterContainer';

import styled from '@emotion/styled';
import colors from '../../../../../helpers/colors';
import StagePub from '../../../Sections/StagePub/StagePub';

const SectionTitle = styled.h1`
    color: ${colors.primary_alt};
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 2em;
   
    margin-bottom: 0;
`

const ServiceContent = styled.div`
    margin-top: 150px;
    margin-bottom: 40px;
    @media (max-width: 1023px){
        margin-top: 40px;
    }
`

const Developpement = ({dataDeveloppement}) => (
    <div className="row">
        <Helmet>
            <title>Services enfance || La Conciergeries des Petits</title>
            <meta name="keywords" content="HTML,CSS,JavaScript"/>
            <meta name="author" content="Adrien Gillmann"/>
            <meta
                name="description"
                content="Les services destinés à l'enfance de 2 à 12 ans proposé par la Conciergeries des Petits"  
            />
        </Helmet>
        <Header
            background={colors.primary_alt}
            textColor={colors.white}
            logoColor="blanc"
        />
        <ServiceContent className="col-xs-12">
            <section className="row" id="educatives">
                <SectionTitle className="col-xs-offset-1 col-xs-10 center-xs">Développement de l'enfant</SectionTitle>
                <div className="col-md-offset-1 col-md-10 col-xs-12">
                    <div className="row">
                    {dataDeveloppement && dataDeveloppement.length > 0 
                        ? (
                        dataDeveloppement.map(service => (
                        <div className="col-md-4 col-sm-6 col-xs-12" key={service.id}>
                            <CardService
                                id={service.id} 
                                key={service.id}
                                name={service.name}
                                description={service.description}
                                capacite={service.capacite}
                                duree={service.duree}
                                prix_m={service.prix_m}
                                prix_p={service.prix_p}
                                illustration={service.illustration.url}
                            />
                        </div>
                        )))
                        : (
                            <div className="col-xs-12 center-xs" style={{marginTop: 80, marginBottom: 50}}>
                                <Loader 
                                    type="Oval"
                                    color="#10909F"
                                    height="35"	
                                    width="35"
                                /> 
                            </div> 
                        )
                    }
                    </div>
                </div>      
            </section>
        </ServiceContent>
        <StagePub/>
        <Information/>
        <Footer/>
    </div>
    
);

export default Developpement;
