import React, { Component } from 'react';
import ServiceEnfance from './ServiceEnfance';
import api from '../../../../helpers/api';

class ServiceEnfanceContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataEducative: "",
            dataSportive: "",
            dataLudique: "",
            dataDeveloppement: ""
        };
    }
    sectionFilter = (section) => {
        if (section === 'educatives') {
            document.getElementById('sportives').style.display = 'none';
            document.getElementById('ludiques').style.display = 'none';
            document.getElementById('developpement').style.display = 'none';
            document.getElementById('educatives').style.display = 'flex';
        }
        else if (section === 'sportives') {
            document.getElementById('educatives').style.display = 'none';
            document.getElementById('ludiques').style.display = 'none';
            document.getElementById('developpement').style.display = 'none';
            document.getElementById('sportives').style.display = 'flex';
        }
        else if (section === 'ludiques') {
            document.getElementById('educatives').style.display = 'none';
            document.getElementById('sportives').style.display = 'none';
            document.getElementById('developpement').style.display = 'none';
            document.getElementById('ludiques').style.display = 'flex';
        }
        else if (section === 'developpement') {
            document.getElementById('educatives').style.display = 'none';
            document.getElementById('sportives').style.display = 'none';
            document.getElementById('ludiques').style.display = 'none';
            document.getElementById('developpement').style.display = 'flex';
        }
    }

    async componentDidMount(){
        window.scrollTo(0, 0);
        const dataEducative = await api.getServiceEnfance("educative");
        const dataSportive = await api.getServiceEnfance("sportive");
        const dataLudique = await api.getServiceEnfance("ludique_creative");
        const dataDeveloppement = await api.getServiceEnfance("developpement_enfant");

        this.setState({
            dataEducative: dataEducative.services,
            dataSportive: dataSportive.services,
            dataLudique: dataLudique.services,
            dataDeveloppement: dataDeveloppement.services
        })
        console.log("state:",this.state);
    }
    render() {
        return (
            <ServiceEnfance
                sectionFilter={this.sectionFilter}
                dataEducative={this.state.dataEducative}
                dataSportive={this.state.dataSportive}
                dataLudique={this.state.dataLudique}
                dataDeveloppement={this.state.dataDeveloppement}
            />
        );
    }
}

export default ServiceEnfanceContainer;
