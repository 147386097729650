import React, { Component } from 'react';
import ServicePEnfance from './ServicePEnfance';

import api from '../../../../helpers/api';

class ServicePEnfanceContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            section: "all",
            dataBienEtre: "",
            dataJourneeBebe: "",
            dataAccompagnement: ""
        };
    }
    
    async componentDidMount(){
        window.scrollTo(0, 0);
        const dataBienEtre = await api.getServicePetiteEnfance("bien_etre");
        const dataJourneeBebe = await api.getServicePetiteEnfance("journee_bebe");
        const dataAccompagnement = await api.getServicePetiteEnfance("accompagnement");
        
        this.setState({
            dataBienEtre: dataBienEtre.services,
            dataJourneeBebe: dataJourneeBebe.services,
            dataAccompagnement: dataAccompagnement.services,
        })

        
        console.log("state:",this.state);
    }
    render() {
        
        return (
            <ServicePEnfance
                section={this.state.section}
                dataBienEtre={this.state.dataBienEtre}
                dataJourneeBebe={this.state.dataJourneeBebe}
                dataAccompagnement={this.state.dataAccompagnement}
            />
        );
    }
}

export default ServicePEnfanceContainer;
