import React from 'react';
import styled from '@emotion/styled';
import colors from '../helpers/colors';

const SectionInformation = styled.section`
  background: ${colors.white}!important;
  padding: 10px 0px;
`

const TextInfo = styled.h4`
  color: ${colors.black};
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 2em;
  margin: 10px 0;
`

const EnConstruction = () => (
    <SectionInformation className="col-xs-12 center-xs" style={{marginTop: 10}}>
        <TextInfo><span style={{fontWeight: "bold"}}>Site en cours de construction</span></TextInfo>
     </SectionInformation>
);

export default EnConstruction;
