import React from 'react';
import styled from '@emotion/styled';
import colors from '../../../helpers/colors';
import { Link } from 'react-router-dom';

const Img = styled.img`
    width: 100%;
    height: auto;
`

const CardContent = styled.div`
    margin: 20px 10px;
    padding: 10px 30px 10px 30px;
`

const HoverContent = styled.div`
    position: relative;
    &:hover .text{
        visibility: visible;
        opacity: 1;
    }
`

const Description = styled.div`
    position: absolute;
    width:100%;
    height:100%;
    top: 0px;
    background: ${colors.primary_alt};
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s, visibility .2s;
    
`

const TextDesc = styled.p`
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 50px;
    padding: 5px 20px;
    
    @media (max-width: 1612px) {
        font-size: 1.4rem;
        line-height: 30px;
        font-weight: 300;
      }
    @media (max-width: 1262px) {
        font-size: 1.3rem;
        line-height: 15px;
        font-weight: 300;
      }
    @media (max-width: 1023px) {
        font-size: 2.4rem;
        line-height: 60px;
        font-weight: 300;
    }
`

const CardServiceProto = ({link, description, illustration}) => (
  <div className="row">
   
    <CardContent className="col-xs-12">
        <Link to={`${link}`} style={{ textDecoration: 'none'}}>
        <HoverContent className="overlay">
            <Img src={illustration}/>
            <Description className="text">
                <TextDesc>{description}</TextDesc>
            </Description>
        </HoverContent>
        </Link>
    </CardContent>
  </div>
);

export default CardServiceProto;
