import React, {Component} from 'react';
import { Helmet } from 'react-helmet';
import api from '../../../helpers/api';
import { Link } from 'react-router-dom';
import Button from '../../Button/ButtonContainer';
import colors from '../../../helpers/colors';
import Header from '../../Header/HeaderContainer';
import Footer from '../../Footer/FooterContainer';
import EnConstruction from '../../EnConstruction';
import StyleProfile from './StyleProfile';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            error: ""
        };
    }
    async componentDidMount(){
        window.scrollTo(0, 0);
        const token = JSON.parse(localStorage.getItem('client_conciergerie')).jwt;
        if(!(localStorage.getItem('info_client'))){
            this.setState({
                error: "Information client introuvable réessayer ou contacter nous"
            })
        } else {
            const id = JSON.parse(localStorage.getItem('info_client')) ? JSON.parse(localStorage.getItem('info_client')).user.infoclient : "undefined";
            const infoclient = await api.getInfoById(id, token);

            if(infoclient.statusCode === 404 || infoclient.statusCode === 401){
                this.setState({
                    error: infoclient.error
                })
            } else {
                this.setState({
                    data: infoclient
                })
            }
        }
        console.log("state:",this.state);
    }
    formatDate(date) {
        let monthNames = [
            "Janvier", "Fevrier", "Mars",
            "Avril", "Mai", "Juin", "Juillet",
            "Aout", "Septembre", "Octobre",
            "Novembre", "Décembre"
        ];
        let dateF = new Date(date);
        let day = dateF.getDate();
        let monthIndex = dateF.getMonth();
        let year = dateF.getFullYear();
        
        return day + ' ' + monthNames[monthIndex] + ' ' + year;
    }
    render() {
        return (
            
            <div className="row">
             <Helmet>
                <title>Profile || La Conciergeries des Petits</title>
                <meta name="keywords" content="HTML,CSS,JavaScript"/>
                <meta name="author" content="Adrien Gillmann"/>
                <meta
                    name="description"
                    content="Profile client"  
                />
            </Helmet>
             <Header/>
             <EnConstruction/>
                <StyleProfile className="col-md-offset-2 col-md-8 col-xs-12" style={{marginBottom: 50}}>
                    <h1 className="center-xs">Bonjour<br/><span>{this.state.error ? this.state.error : this.state.data.prenom}</span></h1>
                    <div className="row">
                        <h2 className="col-xs-12 start-md center-xs">Vos identifiant de connexion</h2>
                        <div className="col-xs-12">
                            <p>Username : {this.state.data.user ? this.state.data.user.username: "null"}</p>
                            <p>Email : {this.state.data.user ? this.state.data.user.email: "null"}</p>
                        </div>
                    </div>
                    <div className="row">
                        <h2 className="col-xs-12 start-md center-xs">Vos informations générales</h2>
                        <div className="col-xs-12">
                            <p>Nom : {this.state.data.nom}</p>
                            <p>Prénom : {this.state.data.prenom}</p>
                            <p>Telephone : {this.state.data.telephone}</p>
                            <p>Adresse : {this.state.data.adresse} {this.state.data.postal}</p>
                        </div>
                    </div>
                    <div className="row">
                        <h2 className="col-xs-12 start-md center-xs">Vos informations d'abonnement et paiement</h2>
                        <div className="col-xs-12">
                            <p>Abonnement choisit : {this.state.data.statut}</p>
                        </div>
                    </div>
                    <div className="row">
                        <h2 className="col-xs-12 start-md center-xs">Vos informations complémentaires</h2>
                        <h3 className="col-xs-12 start-md center-xs">Nombre d'enfants : {this.state.data.enfants ? this.state.data.enfants.length : "0"}</h3>
                        {this.state.data.enfants ? (this.state.data.enfants.map(enfant => (
                            <div className="col-xs-4" key={enfant._id}>
                                <p>Prenom : {enfant.prenom}</p>
                                <p>Nom : {enfant.nom}</p>
                                <p>Date de naissance : {this.formatDate(enfant.birthday)}</p>
                                <p>Nom de l'école : {enfant.ecole}</p>
                            </div>
                            ))): "0"}
                        <h3 className="col-xs-12 start-md center-xs">Contact Supplémentaire : {this.state.data.contactsupps ? this.state.data.contactsupps.length : "0"}</h3>
                        {this.state.data.contactsupps ? (this.state.data.contactsupps.map(contact => (
                            <div className="col-xs-4">
                                <p>{contact.role}</p>
                                <p>Nom : {contact.nom} </p>
                                <p>Prénom : {contact.prenom} </p>
                                <p>Telephone : {contact.telephone}</p>
                            </div>
                            ))): "0"}
                    </div>
                    <Link to="/modification-info" style={{ textDecoration: 'none'}}>
                        <Button 
                            color={colors.white}
                            background={colors.primary}
                            border={"none"}
                            text="Modifier les informations"
                        />
                    </Link> 
                </StyleProfile>
                <Footer/>
            </div>
        );
    }
}

export default Profile;