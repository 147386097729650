import React, { Component } from 'react';
import Button from './Button';

class ButtonInfoContainer extends Component {
    render() {
        return (
            <Button
                color={this.props.color}
                background={this.props.background}
                border={this.props.border}
                text={this.props.text}
            />
        );
    }
}

export default ButtonInfoContainer;
