import React, { Component } from 'react';
import Abonnement from './Abonnement';
import api from '../../../helpers/api';

class AbonnementContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: ""
        };
    }
    async componentDidMount(){
        window.scrollTo(0, 0);
        const data = await api.getPromotion();
        this.setState({data: data[0]})
    }
    render() {
        return (
            <Abonnement
                data={this.state.data}
            />
        );
    }
}

export default AbonnementContainer;