import React, { Component } from 'react';
import Header from './Header';
import { Link } from 'react-router-dom';
import BurgerMenu from './BurgerMenu';
import HeaderContent01 from './HeaderContent01';
import styled from '@emotion/styled';
import colors from '../../helpers/colors';

const HeaderContent= styled.header`
  background: ${colors.primary_alt};
  position: fixed;
  width: 100%;
  z-index:2;
`

const TopHeader = styled.div`
  margin: 5px 0px 0px 0px;
  transition: all 0.5s ease-out;
  @media (max-width: 1023px) {
    display: none
  }
`

const Img = styled.img`
  max-width: 200px;
  height: auto;
  margin: 10px 50px 10px 0px;
  transition: all 0.5s ease-out;
`

const TextLink = styled.h2`
  color: ${colors.white};
  font-family: 'Avenir';
  font-weight: 300;
  font-size: 1em;
   &:hover {
    font-style: bold;
  }
`

const TextUser = styled.span`
    color: ${colors.white};
    font-family: 'Avenir';
    font-weight: 300;
    font-size: 1em;
` 
const IconUser = styled.i`
    color: ${colors.white};
    padding-right: 10px;
`
const ChevronDown = styled.i`
  padding-left: 15px;
  color: ${colors.white};
`

class HeaderContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataCli: [],
            username: "",
        };
    }
    // componentDidUpdate(){
    //     window.onscroll = () => {
    //         if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
    //             document.getElementById("nav").style.padding = "30px 10px";
               
    //         } else {
    //             document.getElementById("nav").style.padding = "80px 10px";
                
    //         }
    //     };
    // }
    
    async componentDidMount() {
        const localData = await JSON.parse(localStorage.getItem('client_conciergerie'));
        const localData2 = await JSON.parse(localStorage.getItem('info_client'));
        if(localData2){
            console.log("localData ------> ", localData);
            let username = await localData2.prenom;
            await this.setState({username: username})
        }
        
    }

    // handleScroll = () => {
    //     this.setState({scrollY: window.scrollY});
    //     let element = document.getElementsByClassName("scroll-header");
    //     if (this.state.scrollY > 200){
    //         for(var i = 0; i < element.length; i++){
    //             element[i].classList.add('change-margin');
    //         }
    //     } else {
    //         for(var i = 0; i < element.length; i++){
    //             element[i].classList.remove('change-margin');
    //         }
    //     }
    // }
    
    render() {
        //,Get data from localstorage"
        if (!localStorage.getItem('client_conciergerie')) {
            if(!localStorage.getItem('info_client')){
                return (
                    <HeaderContent01/>
                );
            }
        } else {
            return (
                <HeaderContent className="col-sm-12" id="nav">
                <TopHeader className="row middle-sm top-header">
                    <Link className="col-sm-3 center-sm" to="/" style={{ textDecoration: 'none'}}>
                        <Img className="logo" src="https://res.cloudinary.com/bomzie/image/upload/v1555511970/conciergerie/logo/LOGO_laconciergeriedespetits_blanc_t9csgt.png" alt="Logo"/> 
                    </Link>
                    <Link className="col-sm-offset-5 col-sm-2 end-md" to="/logout" style={{ textDecoration: 'none'}}> 
                        <TextLink>Déconnexion</TextLink>
                    </Link>
                    <Link className="col-sm-2 center-md" to="/profile" style={{ textDecoration: 'none'}}> 
                        <IconUser className="far fa-user"></IconUser>
                            <TextUser>{this.state.username}</TextUser>
                        <ChevronDown className="fas fa-chevron-down"></ChevronDown>
                    </Link>
                </TopHeader>
                <Header 
                    id="nav"
                    textColor={"white"}
                    background={this.props.background}
                    logoColor={this.props.logoColor}     
                />
                <BurgerMenu/>
                </HeaderContent>
            );
        }
        // console.log(this.state.scrollY);
    }
}
export default HeaderContainer;
