import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Button from '../Button/Button';
import BurgerMenu from './BurgerMenu';

import styled from '@emotion/styled';
import colors from '../../helpers/colors';

const HeaderContent= styled.header`
  background: ${colors.primary_alt};
  position: fixed;
  width: 100%;
  z-index: 2;
`

const TopHeader = styled.div`
  margin: 5px 0px 0px 0px;
  transition: all 0.5s ease-out;
  @media (max-width: 1023px) {
    display: none
  }
`
const Img = styled.img`
  max-width: 200px;
  height: auto;
  margin: 10px 50px 10px 0px;
  transition: all 0.5s ease-out;
`

const TextLink = styled.h2`
  color: ${colors.white};
  font-family: 'Avenir';
  font-weight: 300;
  font-size: 1em;
   &:hover {
    font-style: bold;
  }
`

const HeaderContent01 = () => (
        <HeaderContent className="col-sm-12" id="nav">
        <TopHeader className="row middle-sm top-header">
            <Link className="col-sm-3 center-sm" to="/" style={{ textDecoration: 'none'}}>
                <Img className="logo" src="https://res.cloudinary.com/bomzie/image/upload/v1555511970/conciergerie/logo/LOGO_laconciergeriedespetits_blanc_t9csgt.png" alt="Logo"/> 
            </Link>
            <Link className="col-sm-offset-5 col-sm-2 end-md" to="/register" style={{ textDecoration: 'none'}}> 
                <TextLink>Devenir membre</TextLink>
            </Link>
            <Link className="col-sm-2 center-md" to="/login" style={{ textDecoration: 'none'}}> 
                <TextLink style={{ fontWeight: 'bold'}}>
                <Button
                    color={colors.white}
                    background="#00717F"
                    border="none"
                    text="Connexion"
                /> 
                </TextLink>
            </Link>
        </TopHeader>
        <Header 
            id="nav"
            textColor={colors.white}    
        />
        <BurgerMenu/>
        </HeaderContent>
);
export default HeaderContent01;