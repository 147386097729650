import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import { Helmet } from 'react-helmet';
import Header from '../../../Header/HeaderContainer';
import Footer from '../../../Footer/FooterContainer';
import Styles from './Styles';
import api from '../../../../helpers/api';
import DelayedRedirect from '../../../DelayedRedirect';

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resetOk: false
        };
    }

    onSubmit = async values => {
        const res = await api.postResetPass(values);
        if(res){
            console.log("ForgotPass onsubmit --> ",res);
            if(res.statusCode === 400){
                this.setState({error: res.message});
                console.log(this.state)
                return(res.message);
               
            }
            else {
                this.setState({resetOk:true});
            }
        }
        
    }

    required = value => (value ? undefined : "Champs requis");
    validatePassword = (value) => {
        var re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        if(!re.test(String(value).toLowerCase())){
            return "Mauvais format de mot de passe (8 caractère minimun et un chiffre minimun)";
        }
    }
    composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);
    render() {
        return (
            <div className="row">
            <Helmet>
                <title>Reset password || La Conciergeries des Petits</title>
                <meta name="keywords" content="HTML,CSS,JavaScript"/>
                <meta name="author" content="Adrien Gillmann"/>
                <meta
                    name="description"
                    content="Réinitialiser le mot de passe"  
                />
            </Helmet>
                <Header/>
                <div className="col-xs-12">
                {this.state.resetOk ? (
                    <Styles className="row" style={{marginBottom: 50}}>
                        <h1 className="col-xs-12 center-xs"style={{marginBottom: 200, marginTop: 370}}>Votre nouveau mot de passe a bien été enregistré</h1>
                        <DelayedRedirect to="/login" delay={5000}/>
                    </Styles>) :(
                        <Styles className="row" style={{marginBottom: 50}}>
                            <div className="col-xs-12">
                                <h1 className="center-xs">Réinitialiser votre mot de passe</h1>
                            </div>
                            <div className="col-md-offset-3 col-md-6 col-xs-12">  
                            <Form
                                onSubmit={this.onSubmit}
                                initialValues={{code: this.props.match.params.code}}
                                render={({ handleSubmit, form, submitting, pristine, values }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Field name="password" validate={this.composeValidators(this.required, this.validatePassword)}>
                                            {({ input, meta }) => (
                                                <div>
                                                    <label>Nouveau mot de passe</label>
                                                    <input {...input} type="password" placeholder="Saisisez votre nouveau mot de passe"/>
                                                    {meta.error && meta.touched && <span>{meta.error}</span>}
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="passwordConfirmation" validate={this.composeValidators(this.required, this.validatePassword)}>
                                            {({ input, meta }) => (
                                                <div>
                                                    <label>Confirmation du mot de passe</label>
                                                    <input {...input} type="password" placeholder="Saisisez de nouveau votre mot de passe"/>
                                                    {meta.error && meta.touched && <span>{meta.error}</span>}
                                                </div>
                                            )}
                                        </Field>
                                        <div className="row">
                                            <div className="col-xs-12">
                                                <span>{this.state.error ? this.state.error : ""}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-12 center-xs">
                                                <button type="submit"  disabled={submitting}>
                                                    <span>Enregistrer</span>
                                                </button>
                                            </div>
                                        </div>
                                        <pre>{JSON.stringify(values, 0, 2)}</pre>
                                    </form>
                                )}
                            />
                            </div>
                        </Styles> 
                    )}
                </div>
                <Footer/>
            </div>
        );
        }
}

export default Register;