import React, { Component } from "react";
import SlickSlider from "react-slick";
import CardEquipe from "../Cards/CardEquipe/CardEquipe.js";

class Slider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 765,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const data = require("../Cards/CardEquipe/equipeData.json");
    const equipe = data.equipe;
    return (
      <div className="col-xs-12">
        <SlickSlider {...settings}>
        {equipe.length > 0 && (
            equipe.map(p => (
             <CardEquipe
                key={p.id}
                nom={p.nom}
                prenom={p.prenom}
                photo={p.photo}
                profession={p.profession}
             />
            ))
          )}
        </SlickSlider>
      </div>
    );
  }
}

export default Slider;
