import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../../Header/HeaderContainer';
import Footer from '../../../Footer/FooterContainer';
import Styles from './StylesLogout';
import DelayedRedirect from '../../../DelayedRedirect';

class Logout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmed:false,
            error: ""
        };
    }

    componentDidMount(){
        window.scrollTo(0, 0);
    }
    componentWillUnmount(){
        localStorage.clear();
    }

    render() {        
        return (
            <div className="row">
            <Helmet>
                <title>Confirmation de l'ouverture du compte || La Conciergeries des Petits</title>
                <meta name="keywords" content="HTML,CSS,JavaScript"/>
                <meta name="author" content="Adrien Gillmann"/>
                <meta
                    name="description"
                    content="Confirmation de l'ouverture du compte"  
                />
            </Helmet>
            <Header/>
            <div className="col-xs-12">
                <Styles className="row center-xs" style={{marginBottom: 50, marginTop: 60}}>
                    <h1 className="center-xs">Au revoir {JSON.parse(localStorage.getItem('client_conciergerie')) ? JSON.parse(localStorage.getItem('info_client')).prenom : ""} à bientot</h1>
                    <DelayedRedirect to="/" delay={3000}/>
                </Styles>
            </div>
            <Footer/>
            </div>
        );
        }
}

export default Logout;