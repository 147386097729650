import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Header from '../../Header/HeaderContainer';
import SliderInfo from '../../Slider/SliderInfo';

import styled from '@emotion/styled';
import colors from '../../../helpers/colors';
import Information from '../Sections/Information/Information';
import Footer from '../../Footer/Footer';

const InfoContent = styled.div`
    margin-top: 130px;
    @media (max-width: 1023px){
        margin-top: 0px; 
    }
` 

const Title = styled.h2`
    color: ${colors.primary};
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 2.7rem;
`

const SubTitle = styled.h3`
    color: ${colors.primary};
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 2rem;
`

const TextLink = styled.h3`
    color: ${colors.primary};
    text-transform: capitalize;
    font-family: 'Avenir';
    font-weight: 500;
    font-size: 2rem;
`

const Text = styled.p`
    color: ${colors.black};
    font-family: 'Avenir';
    font-weight: 500;
    font-size: 1rem;
    & > span {
        font-size: 1.4rem;
    }
`

const TextBis = styled.p`
    color: ${colors.black};
    font-family: 'Avenir';
    font-style: oblique;
    font-weight: 500;
    font-size: 1rem;
`

const TextDescription = styled.p`
    color: ${colors.black};
    font-family: 'Avenir';
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 42px;
    margin-bottom: 100px;
`

const TextList = styled.li`
    color: ${colors.black};
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
`
const ChevronLeft = styled.i`
    margin-right: 30px;
`

const Img = styled.img`
    width: 100%;
    height: auto;
`
const InfoService = ({lastLocation, previousLike, name, description, prix_m, prix_p, capacite, illustration, other_img, villes, duree}) => (
    <div className="row">
        <Helmet>
            <title>Service {name} || La Conciergerie des Petits</title>
            <meta name="keywords" content="HTML,CSS,JavaScript"/>
            <meta name="author" content="Adrien Gillmann"/>
            <meta
                name="description"
                content="Information supplémentaire du service"  
            />
        </Helmet>
        <Header
            background={colors.primary_alt}
            textColor={colors.white}
            logoColor="blanc"
        />
        <InfoContent className="col-md-offset-1 col-md-10 col-xs-12 start-sm center-xs">
            <Link to={lastLocation} style={{ textDecoration: 'none'}}>
                <TextLink>
                    <ChevronLeft className="fas fa-chevron-left"></ChevronLeft>
                    {previousLike}
                </TextLink>
            </Link>
            <div className="row">
                <div className="col-sm-4 col-xs-12">
                    <Img src={illustration} alt="Photo"/>
                    <div className="row">
                        <div className="col-xs-6">
                            <Text>Capacité 0-{capacite} pers.</Text>
                        </div>
                        <div className="col-xs-6">
                            <Text>Durée : {duree}</Text>
                        </div>
                    </div>
                    
                   
                </div>
                <div className="col-sm-8 col-xs-12"> 
                    <Title>{name}</Title>
                    <TextDescription>{description}</TextDescription>
                    <div className="row">
                        <div className="col-md-6 col-xs-6">
                            <Text>Prix membre : <span>{prix_m}/enfants</span></Text>
                        </div>
                        <div className="col-md-6 col-xs-6">
                            <Text>Prix public : <span>{prix_p}/enfants</span></Text>
                        </div>
                    </div>
                    <TextBis>N'hésitez pas à nous contacter pour voir la disponibilité de cette activité, par téléphone ou par mail via le formulaire de contact.</TextBis>
                </div>  
            </div>
            <div className="row" style={{marginBottom: "70px"}}>
                <div className="col-xs-12 ">
                    <SubTitle>Cette activité est disponible à :</SubTitle>
                    <ul>
                    {villes.length > 0 && (
                        villes.map(v => (
                        <TextList key={v.id}>
                            {v.name}
                        </TextList>
                        ))
                    )}
                    </ul>
                </div>
            </div>
            {other_img.length > 0 && (
                <div className="row" style={{marginBottom: "70px"}}>
                    <div className="col-xs-12">
                        <SubTitle>Photos de l'activité</SubTitle>
                        <SliderInfo
                            data={other_img}
                        />
                    </div>
                </div>
            )}
            
            
        <Information/>
        </InfoContent>
        <Footer/>
    </div> 
);

export default InfoService;
