import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import { Helmet } from 'react-helmet';
import Header from '../../../Header/HeaderContainer';
import Footer from '../../../Footer/FooterContainer';
import Styles from './Styles';
import api from '../../../../helpers/api';
import DelayedRedirect from '../../../DelayedRedirect';

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailOk:false,
            error: ""
        };
    }
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    onSubmit = async values => {
        const res = await api.postForgotPass(values);
        if(res){
            console.log("ForgotPass onsubmit --> ",res);
            if(res.statusCode === 400){
                this.setState({error: res.message});
                console.log(this.state)
                return(res.message);
               
            }
            else {
                this.setState({emailOk:true});
            }
        }
        
    }

    required = value => (value ? undefined : "Champs requis");
    validateEmail = (value) => {
        var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!re.test(String(value).toLowerCase())){
            return "Mauvais format email";
        }
    }
    composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);
    render() {
        
        return (
            <div className="row">
            <Helmet>
                <title>Mot de passe oublié || La Conciergeries des Petits</title>
                <meta name="keywords" content="HTML,CSS,JavaScript"/>
                <meta name="author" content="Adrien Gillmann"/>
                <meta
                    name="description"
                    content="Formulaire de mot de passe oublié"  
                />
            </Helmet>
                <Header/>
                <div className="col-xs-12">
                {this.state.emailOk ? (
                    <Styles className="row" style={{marginBottom: 50}}>
                        <h1 className="col-xs-12 center-xs"style={{marginBottom: 200, marginTop: 370}}>Un email vous a été envoyer pour réinitialiser votre mot de passe</h1>
                        <DelayedRedirect to="/" delay={5000}/>
                    </Styles>):(
                        <Styles className="row" style={{marginBottom: 50}}>
                        <div className="col-xs-12">
                            <h1 className="center-xs">Commençons par trouver votre compte</h1>
                            <h2 className="center-xs">Saisissez votre e-mail</h2>
                        </div>
                        <div className="col-md-offset-3 col-md-6 col-xs-12">  
                        <Form
                            onSubmit={this.onSubmit}
                            render={({ handleSubmit, form, submitting, pristine, values }) => (
                                <form onSubmit={handleSubmit}>
                                    <Field name="email" validate={this.composeValidators(this.required, this.validateEmail)}>
                                        {({ input, meta }) => (
                                            <div>
                                
                                                <input {...input} type="email" placeholder="Saissisez votre e-mail"/>
                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <span>{this.state.error ? this.state.error : ""}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 center-xs">
                                            <button type="submit"  disabled={submitting}>
                                                <span>Envoyer</span>
                                            </button>
                                        </div>
                                    </div>
                                    
                                    {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                                </form>
                            )}
                        />
                        </div>
                    </Styles>
                    )}
                    
                </div>
                <Footer/>
            </div>
        );
        }
}

export default Register;