import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../Button/ButtonContainer';
//import EnConstruction from '../EnConstruction';
import styled from '@emotion/styled';
import colors from '../../helpers/colors';

const FooterContent = styled.footer`
  background: ${colors.white_alt};
  padding: 20px 0;
`
const TextFooter = styled.h2`
  color: ${colors.primary};
  font-family: 'Avenir Black';
  font-weight: 500;
  font-size: 1.3em;
` 
const TextFooterBis = styled.p`
  color: ${colors.black};
  padding: 0;
  margin: 10px 0px 10px;
  font-family: 'Avenir Black', sans-serif;
  font-weight: 500;
  font-size: 1em;
` 
const Chevron = styled.i`
  color: ${colors.black};
  margin-left: 50px;
  
`
const IconRs = styled.i`
  font-size: 50px;
  color: ${colors.black};
  margin: 10px 35px 0px 0px;
`
const ALink = styled.a`
  text-decoration: none; 
`
const Footer = () => (
  <FooterContent className="col-xs-12">
  
      <div className="row">
        <div className="col-xs-offset-1 col-xs-10">
        <div className="row">
          <div className="col-md-3">
            <TextFooter>Devenir Membre</TextFooter>
              <TextFooterBis>De La Conciergerie des Petits.</TextFooterBis>
              <Link to="/register" style={{ textDecoration: 'none'}}>
                <Button
                  color={colors.white}
                  background={colors.primary_alt}
                  border="none"
                  text="Devenir membre"
                />
              </Link>
            </div>
            <div className="col-md-3 col-xs-12">
              <TextFooter>Contactez-nous</TextFooter>
              <TextFooterBis>La Conciergerie des Petits</TextFooterBis>
              <TextFooterBis><span style={{textDecoration: "underline"}}>06 64 59 05 15</span></TextFooterBis>
              <TextFooterBis><span style={{textDecoration: "underline"}}>contact@laconciergeriedespetits.fr</span></TextFooterBis>
              <TextFooterBis><span style={{textDecoration: "underline"}}>et WhatsApp</span></TextFooterBis>
            </div>
        <div className="col-md-4 col-xs-12">
          <TextFooter>Nos Services</TextFooter>
          <Link className="row middle-xs" to="/services-petite-enfance" style={{ textDecoration: 'none'}}>
            <TextFooterBis className="col-xs-6">Services petite enfance</TextFooterBis>
            <Chevron className="fas fa-chevron-right col-xs-1 middle-xs"></Chevron>
          </Link>
          <Link className="row middle-xs" to="/services-enfance" style={{ textDecoration: 'none'}}>
            <TextFooterBis className="col-xs-6">Services enfance</TextFooterBis>
            <Chevron className="fas fa-chevron-right col-xs-1 middle-xs"></Chevron>
          </Link>
          <Link className="row middle-xs" to="/services-aux-parents" style={{ textDecoration: 'none'}}>
            <TextFooterBis className="col-xs-6">Services aux parents</TextFooterBis>
            <Chevron className="fas fa-chevron-right col-xs-1 middle-xs"></Chevron>
          </Link>
          <Link className="row middle-xs" to="/stage-vacances-scolaires" style={{ textDecoration: 'none'}}>
            <TextFooterBis className="col-xs-6">Stages vacances scolaires</TextFooterBis>
            <Chevron className="fas fa-chevron-right col-xs-1 middle-xs"></Chevron>
          </Link>
          <Link className="row middle-xs" to="/abonnement" style={{ textDecoration: 'none'}}>
            <TextFooterBis className="col-xs-6">Nos abonnements</TextFooterBis>
            <Chevron className="fas fa-chevron-right col-xs-1 middle-xs"></Chevron>
          </Link>
        </div>
        <div className="col-md-2 col-xs-12">
          <TextFooter>Nous Suivre</TextFooter>
          <ALink href="https://www.facebook.com/laconciergeriedespetits.fr/">
            <IconRs className="fab fa-facebook"></IconRs>
          </ALink>
          <ALink href="https://www.instagram.com/laconciergeriedespetits.fr/">
            <IconRs className="fab fa-instagram"></IconRs>
          </ALink>
          {/* <ImageRS src="https://via.placeholder.com/50x50?text=RS"/>
          <ImageRS src="https://via.placeholder.com/50x50?text=RS"/>
          <ImageRS src="https://via.placeholder.com/50x50?text=RS"/> */}
        </div>
      </div>
      </div>
    </div>
  </FooterContent>
);

export default Footer;
