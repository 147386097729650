import styled from '@emotion/styled';

import colors from '../../../../helpers/colors';

export default styled.div`
    h1 {
      color: ${colors.black};
      font-family: 'Avenir';
      font-weight: 900;
      font-size: 2.5em;
      margin-top: 250px;
      @media (max-width: 1023px){
        margin-top: 50px;
      }
    }
`