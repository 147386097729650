import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../../Header/HeaderContainer';
import CardService from '../../../Cards/CardService/CardServiceProtoContainer';

import Information from '../../Sections/Information/Information';
import Footer from '../../../Footer/FooterContainer';

import styled from '@emotion/styled';
import colors from '../../../../helpers/colors';
// import EnConstruction from '../../../EnConstruction';

const SectionTitle = styled.h3`
    color: ${colors.primary_alt};
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 1.8em;
    margin-bottom: 0;
`

const ServiceContent = styled.div`
    margin-bottom: 40px;
    margin-top: 150px;
    @media (max-width: 1023px){
        margin-top: 40px;
    }
`

const ServicePEnfance = () => (
    <div className="row">
        <Helmet>
            <title>Services petite enfance || La Conciergeries des Petits</title>
            <meta name="keywords" content="HTML,CSS,JavaScript"/>
            <meta name="author" content="Adrien Gillmann"/>
            <meta
                name="description"
                content="Les services destinés a la petite enfance jusqu'à 2 ans proposé par la Conciergeries des Petits"  
            />
        </Helmet>
         <Header
            background={colors.primary_alt}
            textColor={colors.white}
            logoColor="blanc"
        />
        {/* <TitleContent className="col-xs-12">
            <div className="row">
                <Title className="col-xs-offset-1 col-xs-10 start-sm center-xs">Services Petite Enfance : <span>Pour les petits de la naissance à 2 ans</span></Title>
            </div>
        </TitleContent> */}
        <ServiceContent className="col-xs-12">
            <section className="row" id="bien-etre">
                {/* <EnConstruction/> */}
                <div className="col-md-offset-1 col-md-10 col-xs-12">
                    <div className="row">
                        <div className="col-md-offset-2 col-md-4 col-sm-6 col-xs-12">
                        <SectionTitle className="center-xs">Bien-être bébé</SectionTitle>
                            <CardService
                                link={"/services-petite-enfance/bien-etre"}
                                name={"Puéricultrice de nuit"}
                                description={"Voir tout nos services bien-être bébé"}
                                capacite={2}
                                duree={"12h"}
                                prix_m={"200€00"}
                                prix_p={"220€00"}
                                illustration={"https://res.cloudinary.com/elbitmedias/image/upload/v1557152309/vauvus4xtfafrlmc7nz8.jpg"}
                            />
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12">
                        <SectionTitle className="center-xs">Accompagnement maman</SectionTitle>
                            <CardService
                                link={"/services-petite-enfance/accompagnement-maman"}
                                name={"Sortie maternité"}
                                description={"Voir tout nos services Accompagnement maman"}
                                capacite={2}
                                duree={"1h"}
                                prix_m={"25€00"}
                                prix_p={"30€00"}
                                illustration={"https://res.cloudinary.com/elbitmedias/image/upload/v1557150902/vmxzpe0ripvwe5r5667w.jpg"}
                            />
                        </div>
                    </div>
                </div>  
            </section>
        </ServiceContent>
        <Information/>
        <Footer/>
    </div>
    
);

export default ServicePEnfance;
