import React, { Component } from 'react';
import Educative from './Educative';
import api from '../../../../../helpers/api';

class EducativeContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataEducative: "",
        };
    }

    async componentDidMount(){
        window.scrollTo(0, 0);
        const dataEducative = await api.getServiceEnfance("educative");

        this.setState({
            dataEducative: dataEducative.services,
        })
        console.log("state:",this.state);
    }
    render() {
        return (
            <Educative
                dataEducative={this.state.dataEducative}
            />
        );
    }
}

export default EducativeContainer;
