import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../../Header/HeaderContainer';
import Footer from '../../Footer/FooterContainer';
import Button from '../../Button/ButtonContainer';

// Lib Styles
import styled from '@emotion/styled';
import colors from '../../../helpers/colors';

const Content = styled.div`
    margin-top: 230px;
    margin-bottom: 30px;
`

const Title = styled.h1`
    color: ${colors.primary};
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 4em;
`

const LinkBis = styled.span`
    color: ${colors.black};
    font-family: 'Avenir';
    font-weight: 500;
    font-size: 1.5em;
`

const Text = styled.p`
    color: ${colors.black};
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 3.5em;
`
const Page404 = () => (
    <div className="row">
        <Header
            background={colors.primary_alt}
            textColor={colors.white}
            logoColor="blanc"
        />
        <Content className="col-xs-offset-1 col-xs-10 center-xs">
            <Title>Erreur 404</Title>
            <Text>La page demandé est introuvable.</Text>
            <div className="row">
                <LinkBis className="col-sm-4 col-xs-12" to="/presentation" style={{marginBottom: 30}}>
                    <Button
                        color={colors.white}
                        background={colors.primary_alt}
                        border="none"
                        text="Présentation"
                        
                    />
                </LinkBis>
                <LinkBis className="col-sm-4 col-xs-12" to="/abonnement" style={{marginBottom: 30}}>
                    <Button
                        color={colors.white}
                        background={colors.primary_alt}
                        border="none"
                        text="Abonnement"
                    />
                </LinkBis>
                <LinkBis className="col-sm-4 col-xs-12" to="/stages-vacances-scolaires" style={{marginBottom: 30}}>
                    <Button
                        color={colors.white}
                        background={colors.primary_alt}
                        border="none"
                        text="Stages Vacances Scolaires"
                    />
                </LinkBis>
                <Link className="col-sm-4 col-xs-12" to="/services-petite-enfance" style={{marginBottom: 30}}>
                    <Button
                        color={colors.white}
                        background={colors.primary}
                        border="none"
                        text="Services petite enfance"
                    />
                </Link>
                <Link className="col-sm-4 col-xs-12" to="/services-enfance" style={{marginBottom: 30}}>
                    <Button
                        color={colors.white}
                        background={colors.primary}
                        border="none"
                        text="Services enfance"
                    />
                </Link>
                <Link className="col-sm-4 col-xs-12" to="/services-aux-parents" style={{marginBottom: 30}}>
                    <Button
                        color={colors.white}
                        background={colors.primary}
                        border="none"
                        text="Services aux parents"
                    />
                </Link>
            </div>
            
        </Content>
        <Footer/>
    </div>
);

export default Page404;