import React, { Component } from 'react';
import Accompagnement from './Accompagnement';

import api from '../../../../../helpers/api';

class AccompagnementContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataAccompagnement: [],
        };
    }

    async componentDidMount(){
        window.scrollTo(0, 0);
        const dataAccompagnement = await api.getServicePetiteEnfance("accompagnement");
        
        this.setState({
            dataAccompagnement: dataAccompagnement.services,
        })

        
        console.log("state:",this.state);
    }
    render() {
        
        return (
            <Accompagnement
                dataAccompagnement={this.state.dataAccompagnement}
            />
        );
    }
}

export default AccompagnementContainer;
