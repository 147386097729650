// LOCAL API
// const SERVICE_PARENT_URL = "http://localhost:1337/subcategories/5cc02d40a883b2134b917a2f";
// const SERVICE_ENFANCE_URL = {
//     "educative": "http://localhost:1337/subcategories/5cc02d0ba883b2134b917a2b",
//     "sportive": "http://localhost:1337/subcategories/5cc02d18a883b2134b917a2c",
//     "ludique_creative": "http://localhost:1337/subcategories/5cc02d27a883b2134b917a2d",
//     "developpement_enfant": "http://localhost:1337/subcategories/5cc02d37a883b2134b917a2e"
// };
// const SERVICE_PETITE_ENFANCE_URL = {
//     "bien_etre": "http://localhost:1337/subcategories/5cc02ce1a883b2134b917a28",
//     "journee_bebe": "http://localhost:1337/subcategories/5cc02cf0a883b2134b917a29",
//     "accompagnement": "http://localhost:1337/subcategories/5cc02cfea883b2134b917a2a"
// };
// const SERVICE_URL = "http://localhost:1337/services/";
// const REGISTER_URL = "http://localhost:1337/auth/local/register";
// const LOGIN_URL = "http://localhost:1337/auth/local";
// const INFOCLIENT_URL = "http://localhost:1337/infoclients/";
// const INFOENFANT_URL = "http://localhost:1337/enfants";
// const INFOCONTACT_URL = "http://localhost:1337/contactsupps";
// const FORGOTPASS_URL = "http://localhost:1337/auth/forgot-password";
// const RESETPASS_URL = "http://localhost:1337/auth/reset-password";
// const CONFIRMEMAIL_URL = "http://localhost:1337/auth/email-confirmation";
// const PROMOTION_URL = "http://localhost:1337/promotions";

// // HEROKU HOST;
const SERVICE_PARENT_URL = "https://api-conciergerie.herokuapp.com/subcategories/5cc039ec5f699700170ab3e4";
const SERVICE_ENFANCE_URL = {
    "educative": "https://api-conciergerie.herokuapp.com/subcategories/5cc039bf5f699700170ab3e0",
    "sportive": "https://api-conciergerie.herokuapp.com/subcategories/5cc039c75f699700170ab3e1",
    "ludique_creative": "https://api-conciergerie.herokuapp.com/subcategories/5cc039d85f699700170ab3e2",
    "developpement_enfant": "https://api-conciergerie.herokuapp.com/subcategories/5cc039e45f699700170ab3e3"
};
const SERVICE_PETITE_ENFANCE_URL = {
    "bien_etre": "https://api-conciergerie.herokuapp.com/subcategories/5cc039955f699700170ab3dd",
    "journee_bebe": "https://api-conciergerie.herokuapp.com/subcategories/5cc039955f699700170ab3dd",
    "accompagnement": "https://api-conciergerie.herokuapp.com/subcategories/5cc039af5f699700170ab3df",
}
const SERVICE_URL = "https://api-conciergerie.herokuapp.com/services/";
const REGISTER_URL = "https://api-conciergerie.herokuapp.com/auth/local/register";
const LOGIN_URL = "https://api-conciergerie.herokuapp.com/auth/local";
const INFOCLIENT_URL = "https://api-conciergerie.herokuapp.com/infoclients/";
const INFOENFANT_URL = "https://api-conciergerie.herokuapp.com/enfants";
const INFOCONTACT_URL = "https://api-conciergerie.herokuapp.com/contactsupps";
const FORGOTPASS_URL = "https://api-conciergerie.herokuapp.com/auth/forgot-password";
const RESETPASS_URL = "https://api-conciergerie.herokuapp.com/auth/reset-password";
const CONFIRMEMAIL_URL = "https://api-conciergerie.herokuapp.com/auth/email-confirmation";
const PROMOTION_URL = "https://api-conciergerie.herokuapp.com/promotions";
const PRIX_URL = "https://api-conciergerie.herokuapp.com/prixes";

class api {

///////////////////////////////// GET
    
    // GET PRIX
    async getPrix() {
        const options = {
            method: 'get',
            headers: {
                'Content-type': 'application/json',
            }
        }
        //console.log("api function : ", subcategories);
        const response = await fetch(PRIX_URL, options);
        const json = await response.json();
        console.log("api json get PRIX ----------> : ", json);
        return json;
    }


    // GET PROMOTION
    async getPromotion() {
        const options = {
            method: 'get',
            headers: {
                'Content-type': 'application/json',
            }
        }
        //console.log("api function : ", subcategories);
        const response = await fetch(PROMOTION_URL, options);
        const json = await response.json();
        console.log("api json get PROMOTION ----------> : ", json);
        return json;
    }
    // GET SERVICE PARENT
    async getServiceParent() {
        const options = {
            method: 'get',
            headers: {
                'Content-type': 'application/json',
            }
        }
        const response = await fetch(SERVICE_PARENT_URL, options);
        const json = await response.json();
        console.log("api json get SERVICEPARENT ----------> : ", json);
        return json;
    }

    // GET SERVICE ENFANCE
    async getServiceEnfance(subcategories) {
        const options = {
            method: 'get',
            headers: {
                'Content-type': 'application/json',
            }
        }
        const response = await fetch(SERVICE_ENFANCE_URL[subcategories], options);
        const json = await response.json();
        console.log("api json get SERVICEENFANCE ----------> : ", json);
        return json;
    }

    // GET SERVICE PETITE ENFANCE
    async getServicePetiteEnfance(subcategories) {
        const options = {
            method: 'get',
            headers: {
                'Content-type': 'application/json',
            }
        }
        //console.log("api function : ", subcategories);
        const response = await fetch(SERVICE_PETITE_ENFANCE_URL[subcategories], options);
        const json = await response.json();
        console.log("api json get SERVICEPENFANCE ----------> : ", json);
        return json;
    }

    // GET SERVICE BY ID
    async getServiceById(id) {
        const options = {
            method: 'get',
            headers: {
                'Content-type': 'application/json',
            }
        }
        let url = SERVICE_URL + id
        const response = await fetch(url, options);
        const json = await response.json();
        console.log("api json get SERVICE---------->  ", json);
        return json;
    }

    //GET INFOCLIENT BY ID
    async getInfoById(id, token) {
        var bearer = 'Bearer ' + token;
        const options = {
            method: 'GET',
            withCredentials: true,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                
            }
        }
        let url = INFOCLIENT_URL + id;
        const response = await fetch(url, options);
        const json = await response.json();
        console.log("api json InfoClient ---------->  ", json);
        return json;
    }

///////////////////////////////// POST

    // POST REGISTER
    async postRegister(param) {
        let body = JSON.stringify(param);
        // console.log(typeof(body));
        // console.log(body);
        const options = {
            method: 'post',
            headers: {
                'Content-type': 'application/json',
            },
            body: body
        }
        let url = REGISTER_URL
        const response = await fetch(url, options);
        const json = await response.json();
        console.log("api json REGISTER ---------->  ", json);
        return json;
    }

    // POST LOGIN
    async postLogin(param) {
        let body = JSON.stringify(param);
        // console.log(typeof(body));
        // console.log(body);
        const options = {
            method: 'post',
            headers: {
                'Content-type': 'application/json',
            },
            body: body
        }
        let url = LOGIN_URL;
        const response = await fetch(url, options);
        const json = await response.json();
        console.log("api json Login : ", json);
        return json;
    }

    // POST INFOCLIENT
    async postInfoClient(param){
        let body = JSON.stringify(param);
        // console.log("BODY API : ", body);
        // console.log("TOKEN : ", bearer);
        const options = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: body
        }
        let url = INFOCLIENT_URL;
        console.log(url,options);
        const response = await fetch(url, options);
        const json = await response.json();
        console.log("api json POSTINFOCLIENT ---------->  ", json);
        return json;
    }

    // POST INFOENFANT
    async postInfoEnfant(param){
        let body = JSON.stringify(param);
        // console.log("BODY API : ", body);
        // console.log("TOKEN : ", bearer);
        const options = {
            method: 'POST',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            body: body
        }
        let url = INFOENFANT_URL;
        // console.log(url,options);
        const response = await fetch(url, options);
        const json = await response.json();
        console.log("api json POSTINFOENFANT ---------->  ", json);
        return json;
    }

    // POST INFOCONTACT
    async postInfoContact(param){
        let body = JSON.stringify(param);
        // console.log("BODY API : ", body);
        // console.log("TOKEN : ", bearer);
        const options = {
            method: 'POST',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            body: body
        }
        let url = INFOCONTACT_URL;
        // console.log(url,options);
        const response = await fetch(url, options);
        const json = await response.json();
        console.log("api json POSTINFOCONTACT ---------->  ", json);
        return json;
    }

    // Update INFOCLIENT
    async updateInfoClient(param, token, id){
        var bearer = 'Bearer ' + token;
        let body = JSON.stringify(param);
        // console.log("BODY API : ", body);
        // console.log("TOKEN : ", bearer);
        const options = {
            method: 'put',
            withCredentials: true,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
            },
            body: body
        }
        let url = INFOCLIENT_URL + id;
        console.log(url,options);
        const response = await fetch(url, options);
        const json = await response.json();
        console.log("api json PUTINFOCLIENT ---------->  ", json);
        return json;
    }

    // UPDATE INFOENFANT
    async updateInfoEnfant(param,token,id){
        var bearer = 'Bearer ' + token;
        let body = JSON.stringify(param);
        // console.log("BODY API : ", body);
        // console.log("TOKEN : ", bearer);
        const options = {
            method: 'put',
            withCredentials: true,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
            },
            body: body
        }
        let url = INFOENFANT_URL + '/'+ id;
        // console.log(url,options);
        const response = await fetch(url, options);
        const json = await response.json();
        console.log("api json PUTINFOENFANT ---------->  ", json);
        return json;
    }

    // UPDATE INFOCONTACT
    async updateInfoContact(param, token,id){
        var bearer = 'Bearer ' + token;
        let body = JSON.stringify(param);
        // console.log("BODY API : ", body);
        // console.log("TOKEN : ", bearer);
        const options = {
            method: 'put',
            withCredentials: true,
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
            },
            body: body
        }
        let url = INFOCONTACT_URL + "/" + id;
        // console.log(url,options);
        const response = await fetch(url, options);
        const json = await response.json();
        console.log("api json PUTINFOCONTACT ---------->  ", json);
        return json;
    }

    // POST ForgotPass
    async postForgotPass(param){
        let body = JSON.stringify(param);
        // console.log("BODY API : ", body);
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: body
        }
        let url = FORGOTPASS_URL;
        // console.log(url,options);
        const response = await fetch(url, options);
        const json = await response.json();
        console.log("api json POSTFORGOTPASS ---------->  ", json);
        return json;
    }

    async postResetPass(param){
        let body = JSON.stringify(param);
        // console.log("BODY API : ", body);
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: body
        }
        let url = RESETPASS_URL;
        // console.log(url,options);
        const response = await fetch(url, options);
        const json = await response.json();
        console.log("api json POSTRESETPASS ---------->  ", json);
        return json;
    }

    async postConfirmEmail(param){
        let body = JSON.stringify(param);
        // console.log("BODY API : ", body);
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: body
        }
        let url = CONFIRMEMAIL_URL;
        // console.log(url,options);
        const response = await fetch(url, options);
        const json = await response.json();
        console.log("api json POSTCONFIRMEMAIL---------->  ", json);
        return json;
    }
}

export default new api();