import React from 'react';

import styled from '@emotion/styled';
import colors from '../../../helpers/colors';

//////////////////// STYLES 

const Check = styled.i`
    margin-right: 10px;
    color: ${props => props.color};
`

const AbonnementContent = styled.div`
    padding: 0px 20px;
    
`

const OffreContent = styled.div`
    margin: 20px 0;
`

////////// TYPO 

// const TextMembre = styled.h3`
//     color: ${colors.black};
//     font-family: 'Avenir';
//     font-weight: 900;
//     font-size: 1.5em;
//     margin: 0;
// `

const TextMembreBis = styled.h3`
    color: ${colors.black};
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 2em;
    margin: 5px 0 15px 0;
`

const TextPrice = styled.p`
    color: ${colors.black};
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 1em;
    margin: 0;
`

const TextPriceBis = styled.p`
    color: ${colors.grey};
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 1em;
    margin: 5px 0 10px 0;
`

const TextOffre = styled.p`
    color: ${colors.black};
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 1em;
    margin: 0;
`

const TextDisable = styled.p`
    color: ${colors.grey};
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 1em;
    margin: 0;
    & > span {
        color: ${colors.white};
    }
`

//////////////////// VIEW 

const Abonnement = () => (
    <div className="row">
        <AbonnementContent className="col-xs-12">
            <TextMembreBis>A la carte</TextMembreBis>
            <TextPrice>Sans abonnement</TextPrice>
            <TextPriceBis >annuel ou mensuel</TextPriceBis>
            <TextPriceBis>&nbsp;</TextPriceBis>
            <TextPrice>Tarif public</TextPrice>
            <TextPriceBis>sans réduction</TextPriceBis>
            <OffreContent className="row top-xs">
                <Check color={colors.primary} className="fas fa-check col-xs-1"></Check>
                <TextOffre className="col-xs-10 start-xs top-xs ">Demande traitée du lundi au vendredi 9h-18h</TextOffre>
            </OffreContent>
            <OffreContent className="row top-xs">
                <Check color={colors.primary} className="fas fa-check col-xs-1"></Check>
                <TextOffre className="col-xs-10 start-xs top-xs">Accès aux services et prestations 7j/7</TextOffre>
            </OffreContent>
            <OffreContent className="row top-xs">
                <Check color={colors.primary} className="fas fa-check col-xs-1"></Check>
                <TextOffre className="col-xs-10 start-xs top-xs">Tarif public pour toutes prestations</TextOffre>
            </OffreContent>
            <OffreContent className="row top-xs">
                <Check color={colors.primary} className="fas fa-check col-xs-1"></Check>
                <TextOffre className="col-xs-10 start-xs top-xs">Stages vancances scolaires en fonction des places disponible</TextOffre>
            </OffreContent>
            <OffreContent className="row top-xs">
                <Check color={colors.grey} className="fas fa-check col-xs-1"></Check>
                <TextDisable className="col-xs-10 start-xs top-xs">Demande et offre sur mesure</TextDisable>
            </OffreContent>
            <OffreContent className="row top-xs">
                <Check color={colors.grey} className="fas fa-check col-xs-1"></Check>
                <TextDisable className="col-xs-10 start-xs top-xs">Demande illimitée</TextDisable>
            </OffreContent>
            <OffreContent className="row top-xs">
                <Check color={colors.white} className="fas fa-check col-xs-1"></Check>
                <TextDisable className="col-xs-10 start-xs top-xs"><span>Demande illimitée</span></TextDisable>
            </OffreContent>
        </AbonnementContent>
    </div>
)

export default Abonnement;