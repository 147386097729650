// Lib React
import React from 'react';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

// Components
import Header from '../../Header/HeaderContainer';
import Footer from '../../Footer/FooterContainer';
import Button from '../../Button/ButtonContainer';
import Abonnements from '../../Cards/Abonnements/AbonnementContainer';
import Information from '../Sections/Information/Information';

// Lib Styles
import styled from '@emotion/styled';
import colors from '../../../helpers/colors';
//import EnConstruction from '../../EnConstruction';

//////////////////// STYLES

const SectionConcept = styled.section`
  margin: 135px 0 10px 0;
  padding-left: 0;
  padding-right: 0;
  @media (max-width: 1023px) {
    margin: 0px 0px 40px 0px;
    /* background-image: url("https://res.cloudinary.com/bomzie/image/upload/v1555511827/conciergerie/img/illustration-parc-V2_ub2gha.jpg");
    background-repeat: repeat;
    background-attachment: fixed;
    background-size: contain;
    padding: 20px 0; */
   }
   @media (max-width: 765px) {
    
    /* background-image: url("https://res.cloudinary.com/bomzie/image/upload/v1555511827/conciergerie/img/illustration-parc-V2_ub2gha.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: contain;
    padding-top: 50px; 
    height: 300px; */
   }
`

const SectionServices = styled.section`
  margin-bottom: 20px;
`

const SectionAbonnement = styled.section`
  margin: 0;
`

const SectionPublicitaire = styled.section`
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 40px;
  background: url(form_bandeau.svg) no-repeat center center scroll;
  background-size: cover;
`
const CardContent = styled.div`
   
` 

const ImgMain = styled.img`
  width: 100%;
  max-height: 45vh;
  padding-left: 0;
  padding-right: 0;
  /* @media (max-width: 1023px) {
   display: none;
  } */
`

const ImgService = styled.img`
  max-width: 312px;
  height: auto;
`

////////// TYPO

const BaseLine = styled.h1`
  color: ${colors.primary};
  font-family: 'Avenir';
  font-weight: 900;
  font-size: 2.75rem;
  margin-bottom: 50px;
`

const Title = styled.h2`
  color: ${colors.primary};
  font-family: 'Avenir';
  font-weight: 900;
  font-size: 2.5em;
  margin: 50px 0px;
`

const SubTitle = styled.h3`
  color: ${colors.primary};
  font-family: 'Avenir';
  font-weight: 700;
  font-size: 1.5em;
  margin: 30px 0px;
`

const Paragraphe = styled.p`
  color: ${colors.black};
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 1em;
`
const TextPub = styled.h4`
  color: ${colors.white};
  font-family: 'Avenir';
  font-weight: 700;
  font-size: 1.5em;
  margin: 10px 0;
`

const TextPubBis = styled.h4`
  color: ${colors.white};
  font-family: 'Avenir';
  font-weight: 700;
  font-size: 2em;
  margin: 0;
`


//////////////////// VIEW

const Home = ({data}) => (
  <div className="row">
    <Helmet>
      <title>La Conciergeries des Petits</title>
      <meta name="keywords" content="HTML,CSS,JavaScript"/>
      <meta name="author" content="Adrien Gillmann"/>
      <meta
        name="description"
        content="La Conciergerie des Petits c'est avant tout une conciergerie haut de gamme dédiée aux enfants de tout âge"  
      />
    </Helmet>
    <Header
      background={colors.primary_alt}
      textColor={colors.white}
      logoColor="blanc"
    />
    
    {/* CONCEPT */}
    <SectionConcept className="col-sm-12 center-md">
      <div className="row middle-sm">
        <div className="col-sm-6 col-xs-12">
          <div className="row">
            <div className="col-xs-12 center-xs">
              <BaseLine >Des experts et professionnels de<span style={{display: "block"}}> l’enfance à vos côtés en permanence </span></BaseLine>
            </div>
            <div className="col-sm-offset-3 col-sm-3 col-xs-6 center-xs"  >
              <Link to="/register" style={{ textDecoration: 'none'}}>
                <Button 
                  color={colors.white}
                  background={colors.primary_alt}
                  border="none"
                  text="Devenir membre"
                />
              </Link>
            </div>
            <div className="col-sm-3 col-xs-6 center-xs">
            <Link to="/presentation" style={{ textDecoration: 'none'}}>
              <Button
                color={colors.primary_alt}
                background={colors.white}
                border={"2px solid " + colors.primary_alt}
                text="Nous découvrir"
              />
            </Link>
            </div>
          </div>
        </div>
        <ImgMain className="col-sm-6 col-xs-12 last-sm first-xs" src="https://res.cloudinary.com/bomzie/image/upload/v1555511827/conciergerie/img/illustration-parc-V2_ub2gha.jpg" alt="Illustration"/> 
      </div>
    </SectionConcept>

    {/* Services */}
    <SectionServices className="col-xs-12 center-xs">
      <div className="row">
      {/* <EnConstruction/> */}
        <div className="col-xs-12">
          <Title>Des services haut de gamme à domicile.</Title>
        </div>
        <CardContent className="col-md-4 col-sm-6 col-xs-12">
          <Link to="/services-petite-enfance" style={{ textDecoration: 'none'}}>
            <ImgService src="https://res.cloudinary.com/bomzie/image/upload/v1555511822/conciergerie/img/PROMENADE-GARDE-BEBE_pkqxmo.jpg" alt="Illustration"/>
          </Link>
          <SubTitle>Services<span style={{display: "block"}}>petite enfance</span></SubTitle>
          <Paragraphe>Dédiés aux tout petits<span style={{display: "block"}}>de la naissance à 2 ans.</span></Paragraphe>
          <Link to="/services-petite-enfance" style={{ textDecoration: 'none'}}>
            <Button
              color={colors.white}
              background={colors.primary_alt}
              border={"none"}
              text="Voir plus" 
            />
          </Link>
        </CardContent>
        <CardContent className="col-md-4 col-sm-6 col-xs-12">
          <Link to="/services-enfance" style={{ textDecoration: 'none'}}>
            <ImgService src="https://res.cloudinary.com/bomzie/image/upload/v1555511806/conciergerie/img/ATELIER-MAGIE_g9ttd3.jpg" alt="Illustration"/>
          </Link>  
          <SubTitle>Services<span style={{display: "block"}}>enfance</span></SubTitle>
          <Paragraphe>Pour les enfants<span style={{display: "block"}}>de 3 à 12 ans.</span></Paragraphe>
          <Link to="/services-enfance" style={{ textDecoration: 'none'}}>
            <Button
              color={colors.white}
              background={colors.primary_alt}
              border={"none"}
              text="Voir plus" 
            />
          </Link>        
        </CardContent>
        <CardContent className="col-md-4 col-sm-12 col-xs-12">
          <Link to="/services-aux-parents" style={{ textDecoration: 'none'}}>
            <ImgService src="https://res.cloudinary.com/bomzie/image/upload/v1555511825/conciergerie/img/COIFFEUR-A-DOMICILE_x9oeye.jpg" alt="Illustration"/>
          </Link>
          <SubTitle>Services<span style={{display: "block"}}>aux parents</span></SubTitle>
          <Paragraphe>Pour vous facilitez<span style={{display: "block"}}>la vie !</span></Paragraphe>
          <Link to="/services-aux-parents" style={{ textDecoration: 'none'}}>
            <Button
              color={colors.white}
              background={colors.primary_alt}
              border={"none"}
              text="Voir plus" 
            />
          </Link>
        </CardContent>
      </div>
    </SectionServices>

    {/* Abonnement */}
    <SectionAbonnement className="col-xs-12 center-xs">
      <div className="row center-xs">
        <Title className="col-sm-12 center-xs">Nos abonnements</Title>
        <Abonnements/>
        <Paragraphe style={{marginTop: "10px",fontWeight:"bold"}} className="col-md-12 center-md">* Du lundi au vendredi</Paragraphe>
      </div>
    </SectionAbonnement>

    {/* PUB */}
    <SectionPublicitaire className="col-md-offset-1 col-md-10 col-xs-12"> 
        <div className="row">
          <div className="col-md-offset-2 col-md-4 col-xs-12 start-md center-xs">
            <TextPub style={{marginBottom: 5}}>{data ? data.text_gauche_haut : "Offre promotionnelle"}</TextPub>
            <TextPubBis>{data ? data.text_gauche_bas : "Famille nombreuse"}</TextPubBis>
          </div>
          <div className="col-md-6 col-xs-12 start-md center-xs">
            <TextPub>{data ? data.text_droit_haut : "Famille nombreuse"}</TextPub>
            <TextPub>{data ? data.text_droit_bas : "1 prestations de babysitting (4h) offerte !"}</TextPub>
          </div>
        </div>
    </SectionPublicitaire>
    {/* Information */}
    <Information/>

    <Footer/>

  </div>
);

export default Home;
