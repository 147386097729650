import React, { Component } from "react";
import SlickSlider from "react-slick";

import styled from '@emotion/styled';


const Img = styled.img`
    width: 100%;
    height: 100%;
`

const ImgContent = styled.div`
    padding: 10px 30px;
`
class Slider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 750,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const data = require("./imageDataStage.json");
    const photos = data.photos;
    console.log(photos);
    return (
      <div className="col-xs-12">
        <SlickSlider {...settings}>
        {photos.length > 0 && (
            photos.map(p => (
            <div className="row" key={p.id}>
                <ImgContent className="col-xs-12">
                    <Img src={p.img_src} alt={p.img_alt} />
                </ImgContent>
            </div>
            ))
          )}
        </SlickSlider>
      </div>
    );
  }
}

export default Slider;
