import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import { Link, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../../../Header/HeaderContainer';
import Footer from '../../../Footer/FooterContainer';
import ErrorWithDelay from '../ErrorWithDelay';
import StylesLogin from './StylesLogin';
import api from '../../../../helpers/api';
import EnConstruction from '../../../EnConstruction';

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginOK: false,
            errorLogin: ""
        };
    }
    // async componentDidMount() {
    //     // Si localStorage pas initialisé => premiere visite
    //     if (!localStorage["categories"]) {
    //       // Appel a l'api
    //       const data = await api.getCategories();
    //       // Suppression des categories avec plus de 5 questions
    //       data.map(category => {
    //         data.splice(data.findIndex(category => category.clues_count > 5 ), 1);
    //         return 0;
    //       })
    //       // Initialisation de localStorage et mis a jour des state
    //       const storage = JSON.stringify(data); // mettre dans le bon format
    //       localStorage.setItem("categories", storage);
    //       localStorage.setItem("attempt", this.state.attempt);
    //       this.setState({categories: data,});
    
    //     }
    //     // Si localStorage déjà existant => retour de l'utilisateur sur la page
    //     else {
    //       // Recuperer les donner du localStorage et mise a jour des state
    //       let recup = localStorage.getItem("categories");
    //       let newData = JSON.parse(recup); // Mettre dans le bon format
    //       let attempt = localStorage.getItem("attempt");
    //       let newAttempt = JSON.parse(attempt);
    //       this.setState({
    //         categories: newData,
    //         attempt: newAttempt
    //       });
    //     }
    //   }
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    onSubmit = async values => {
        const res = await api.postLogin(values);
        if(res){
            console.log("Login onsubmit --> ",res);
            if(res.user){
                this.setState({loginOK: true});
                await localStorage.setItem('client_conciergerie',JSON.stringify(res))
                let infoClient = await api.getInfoById(res.user.infoclient,res.jwt);
                await localStorage.setItem('info_client',JSON.stringify(infoClient));
                return(res);
            }
            if(res.statusCode === 400){
                this.setState({errorLogin: res.message})
                return(res.message);
            }
            
        }
        
    }

    //TEST REQUIRED VALIDATE EMAIL
    required = value => (value ? undefined : "Champs requis");
    //
    composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);

    render() {
        console.log(this.state);
        if(this.state.loginOK === true){
            // localStorage.setItem('client_conciergerie',JSON.stringify(res.message));
            // c = localStorage.getItem('client_conciergerie')
            // console.log(JSON.parse(c))

            //console.log("lllllaaaa : localStorage --> :",localStorage.getItem('client_conciergerie'))
            return(
                <Redirect to="/profile"/>
            )            
        } else {
            
            // const dataCli = this.onSubmit()
            // console.log("Render dataCli --> ", dataCli);
            return (
                <div className="row">
                <Helmet>
                    <title>Login || La Conciergeries des Petits</title>
                    <meta name="keywords" content="HTML,CSS,JavaScript"/>
                    <meta name="author" content="Adrien Gillmann"/>
                    <meta
                        name="description"
                        content="Formulaire de connexion a son compte la conciergeries des petits"  
                    />
                </Helmet>
                     <Header/>
                <div className="col-xs-12">
                <StylesLogin className="row" style={{marginBottom: 50, marginTop: 50}}>
                <div className="col-xs-12">
                    <h1 className="center-xs">Connexion</h1>
                </div>
                <div className="col-md-offset-3 col-md-6 col-xs-12">
                    <Form
                    onSubmit={this.onSubmit}
                    // initialValues={{ firstName: 'Bob' }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Field name="identifier" validate={this.required}>
                            {({ input, meta }) => (
                            <div>
                                <label>Identifier</label>
                                <input {...input} type="text" placeholder="" />
                                {meta.error && meta.touched && <span>{meta.error}</span>}
                            </div>
                            )}
                        </Field>
                        <Field name="password" validate={this.required}>
                            {({ input, meta }) => (
                            <div>
                                <label>Mot de passe</label>
                                <input {...input} type="password" placeholder="" />
                                <ErrorWithDelay name="password" delay={3000}>
                                    {error => <span>{error}</span>}
                                </ErrorWithDelay>
                            </div>
                            )}
                        </Field>
                        <div className="row">
                            <div className="col-xs-12 center-xs">
                                <span id="error-login">{this.state.errorLogin}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                <p className="center-xs">Vous n'avez pas encore de compte ? Inscrivez vous des maintenant en suivant ce <Link to="/register">lien</Link></p>
                                <p className="center-xs"><Link to="/forgot-password">Mot de passe oublié ?</Link></p>
                            </div>
                            <div className="col-xs-offset-3 col-xs-6 center-xs">
                                <button type="submit"  disabled={submitting}>
                                    <span>Connexion</span>
                                </button>
                            </div>
                            {/* <div className="col-xs-6 center-xs">
                                <button type="button" onClick={form.reset} disabled={submitting || pristine}>
                                    <span>Réinitialiser</span>
                                </button>
                            </div> */}
                        </div>
                        
                       
                        {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                    </form>
                    )}
                    />
                </div>
                
            </StylesLogin>
            <EnConstruction/>
            </div>
            <Footer/>
            </div>
            );
        }
    }
}

export default Register;