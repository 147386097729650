import React, { Component } from 'react';
import Ludique from './Ludique';
import api from '../../../../../helpers/api';

class LudiqueContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLudique: [],
        };
    }

    async componentDidMount(){
        window.scrollTo(0, 0);
        const dataLudique = await api.getServiceEnfance("ludique_creative");

        this.setState({
            dataLudique: dataLudique.services,
        })
        console.log("state:",this.state);
    }
    render() {
        return (
            <Ludique
                dataLudique={this.state.dataLudique}
            />
        );
    }
}

export default LudiqueContainer;
