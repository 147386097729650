import React, { Component } from 'react';
import InfoService from './InfoService';
import { withLastLocation } from 'react-router-last-location';
import api from '../../../helpers/api';

class InfoServiceContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prevLocationPath: "",
            previousLike: "",
            id: this.props.match.params.id,
            name: "",
            description:"", 
            prix_m: "", 
            prix_p:"",
            capacite: 0,
            duree:"",
            illustration:"",
            other_img:[],
            villes:[]
        };
    }
    async componentDidMount(){
        window.scrollTo(0, 0);
        if(this.props.lastLocation){
            var previousLike = this.props.lastLocation.pathname;
            previousLike = previousLike.slice(1).split('-').join(' ');
            this.setState({
                previousLike: previousLike,
                prevLocationPath: this.props.lastLocation.pathname
            });        
        }
        let data = await api.getServiceById(this.state.id);
        this.setState({
            name: data.name,
            description:data.description, 
            prix_m: data.prix_m, 
            prix_p:data.prix_p,
            capacite: data.capacite,
            duree:data.duree,
            illustration:data.illustration.url,
            other_img:data.other_img,
            villes:data.villes
        })
        console.log("ville:",data.villes);

    }
    render() {
        //console.log(this.state);
        return (
            <InfoService
                previousLike={this.state.previousLike}
                lastLocation={this.state.prevLocationPath}
                id={this.state.id}
                name={this.state.name}
                description={this.state.description}
                prix_m={this.state.prix_m}
                prix_p={this.state.prix_p}
                capacite={this.state.capacite}
                duree={this.state.duree}
                illustration={this.state.illustration}
                other_img={this.state.other_img}
                villes={this.state.villes}
            />
        );
    }
}

export default withLastLocation(InfoServiceContainer);
