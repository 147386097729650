import React, { Component } from 'react';
import ServiceParent from './ServiceParent';
import api from '../../../../helpers/api';

class ServiceParentContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: ""
        };
    }
    async componentDidMount(){
        window.scrollTo(0, 0);
        const data = await api.getServiceParent();
        this.setState({
            data: data.services
        })
        console.log("state:",this.state.data);
        console.log(this.state.data[2].description);
        if(this.state.data[2].description.length > 260){
            let test = this.state.data[2].description.substr(0,260).concat(' ', '...');
            console.log(test);
        }
        
    }
    render() {
        return (
            <ServiceParent
                data={this.state.data}
            />
        );
    }
}

export default ServiceParentContainer;
