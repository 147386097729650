import styled from '@emotion/styled';

import colors from '../../../../helpers/colors';

export default styled.div`
    h1 {
      color: ${colors.primary_alt};
      font-family: 'Avenir';
      font-weight: 900;
      font-size: 2.5em;
      margin-top: 130px;
      @media (max-width: 1023px){
        margin-top: 50px;
      }
    }

    p {
      color: ${colors.black};
      font-family: 'Avenir';
      font-weight: 600;
      font-size: 1.2em;
      margin: 30px 0px;
    }

    label {
      display: block;
      color: ${colors.black};
      font-family: 'Avenir';
      font-weight: 300;
      font-size: 1em;
      margin: 10px 0px;
    }
    input {
      font-family: 'Avenir';
      font-weight: 300;
      font-size: 1em;
      box-sizing: border-box;
      width: 100%;
      padding:10px 15px; 
      &[type='ratio']{
        display: inline-block;
      }
      &:focus{
        border: 2px solid ${colors.primary_alt};
      }
      @media (max-width: 1023px){
        width: 95%;
      }
    }

    span {
      display block;
      font-family: 'Avenir';
      font-weight: 300;
      font-size: 0.80em;
      margin-top: 5px;
      color:red;

    }
    #error-login {
      text-align:center;
    }
    
    button {
      color: ${colors.white};
      background: ${colors.primary};
      border: none;
      border-radius: 34px;
      padding: 13px 23px;
      width: 70%; 
      box-shadow: 0 1px 2px rgba(0,0,0,0.15);
      transition: 0.33s;
      cursor:pointer;
      
      &:after {
        box-shadow: 0 1px 2px rgba(0,0,0,0.15);
      }
      &:hover {
        box-shadow: 0 4px 9px rgba(0,0,0,0.3);
        position:relative;
        top: -2px;
      }
      & > span {
        font-family: 'Avenir';
        font-weight: 700;
        letter-spacing: 0.1em;
        font-size: 1em;
        color: ${colors.white};
      }
      a{
        color: ${colors.primary_alt}
      }
`