import React, { Component } from 'react';
import BienEtre from './BienEtre';

import api from '../../../../../helpers/api';

class BienEtreContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataBienEtre: [],
        };
    }

    async componentDidMount(){
        window.scrollTo(0, 0);
        const dataBienEtre = await api.getServicePetiteEnfance("journee_bebe");
        
        this.setState({
            dataBienEtre: dataBienEtre.services,
        })

        
        console.log("state:",this.state);
    }
    render() {
        
        return (
            <BienEtre
                dataBienEtre={this.state.dataBienEtre}
            />
        );
    }
}

export default BienEtreContainer;
