import React from 'react';

import styled from '@emotion/styled';
import colors from '../../../helpers/colors';

//////////////////// STYLES 

const Check = styled.i`
    margin-right: 10px;
    color: ${colors.primary}
`

const AbonnementContent = styled.div`
    padding: 0px 20px;
`

const OffreContent = styled.div`
    margin: 20px 0;
`

////////// TYPO 

/* const TextMembre = styled.h3`
    color: ${colors.black};
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 1.5rem;
    margin: 0;
` */

const TextMembreBis = styled.h3`
    color: ${colors.primary_alt};
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 2em;
    margin: 5px 0 15px 0;
`

const TextPrice = styled.p`
    color: ${colors.primary_alt};
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 1em;
    margin: 0;
`

const TextPriceBis = styled.p`
    color: ${colors.black};
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 1em;
    margin: 5px 0 10px 0;
`

const TextOffre = styled.p`
    color: ${colors.black};
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 1em;
    margin: 0;
`

//////////////////// VIEW 

const Abonnement = ({prixMois,prixAnnee}) => (
    <div className="row">
        <AbonnementContent className="col-xs-12">
            <TextMembreBis>Infinite</TextMembreBis>
            <TextPriceBis>&nbsp;</TextPriceBis>
            <TextPrice>&nbsp;</TextPrice>
            <TextPrice>{prixMois ? prixMois : "39.99€"}€ / mois / famille</TextPrice>
            <TextPriceBis>Résiliable sans préavis</TextPriceBis>
            <TextPriceBis>&nbsp;</TextPriceBis>
            <OffreContent className="row top-xs">
                <Check className="fas fa-check col-xs-1"></Check>
                <TextOffre className="col-xs-10 start-xs top-xs">Concierge privé personnel 5j/7*</TextOffre>
            </OffreContent>
            <OffreContent className="row top-xs">
                <Check className="fas fa-check col-xs-1"></Check>
                <TextOffre className="col-xs-10 start-xs top-xs">Joignable par téléphone, sms et WhatApp</TextOffre>
            </OffreContent>
            <OffreContent className="row top-xs">
                <Check className="fas fa-check col-xs-1"></Check>
                <TextOffre className="col-xs-10 start-xs top-xs">Accès aux services et prestations 7j/7</TextOffre>
            </OffreContent>
            <OffreContent className="row top-xs">
                <Check className="fas fa-check col-xs-1"></Check>
                <TextOffre className="col-xs-10 start-xs top-xs">Tarif préférentiel pour toutes prestations</TextOffre>
            </OffreContent>
            <OffreContent className="row top-xs">
                <Check className="fas fa-check col-xs-1"></Check>
                <TextOffre className="col-xs-10 start-xs top-xs">Prioritaire pour les stages de vancances scolaires</TextOffre>
            </OffreContent>
            <OffreContent className="row top-xs">
                <Check className="fas fa-check col-xs-1"></Check>
                <TextOffre className="col-xs-10 start-xs top-xs">Demande et offre sur mesure</TextOffre>
            </OffreContent>
            <OffreContent className="row top-xs">
                <Check className="fas fa-check col-xs-1"></Check>
                <TextOffre className="col-xs-10 start-xs top-xs">Demande illimitée</TextOffre>
            </OffreContent>
        </AbonnementContent>
    </div>
)

export default Abonnement;