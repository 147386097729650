// Lib React
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Components
import Header from '../../Header/HeaderContainer';
import Button from '../../Button/ButtonContainer';
import Slider from '../../Slider/SliderEquipe';
import Information from '../Sections/Information/Information';
import Footer from '../../Footer/FooterContainer';

// Lib Styles
import styled from '@emotion/styled';
import colors from '../../../helpers/colors';
//import EnConstruction from '../../EnConstruction';

const SubTitle = styled.h2`
    color: ${colors.primary};
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 2em;
    margin: 40px 0;
    @media (max-width: 700px) {
        font-size: 1.5em;
    }
`

const SubTitleBis = styled.h2`
    color: ${colors.primary};
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 2.3em;
    margin: 40px 0;
    @media (max-width: 700px) {
        font-size: 1.3em;
    }
`

const SubTitleAlt = styled.h3`
    color: ${colors.primary};
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 1.5em;
    margin-top: 0;
    @media (max-width: 700px) {
        font-size: 1em;
    }
    @media (max-width: 1253px) {
        font-size: 1.3em;
    }
`

const Paragraphe = styled.p`
    color: ${colors.black};
    padding-bottom: 20px;
    font-family: 'Avenir';
    font-weight: 300;
    font-size: 1em;
    line-height: 22px;
    text-align: justify;
    text-justify: distribute;
    text-align-last: left;

    /* word-break: break-all; */
    @media (max-width: 700px) {
        font-size: 1em;
    }
`

const TopContent =styled.div`
    margin-top: 130px;
    @media (max-width: 1023px){
        margin-top: 0px;
    }
`


const SerciveContent = styled.div`
    margin: 0 30px;
    flex-wrap: wrap;
    flex: 1 1 auto; 
    padding: 30px 30px 0px 30px;
    background: ${colors.white_alt};
    min-height: 280px;
    @media (max-width: 1253px){
        padding: 30px 15px 0px 15px;
    }
`

const SerciveContentBis = styled.div`
    margin: 20px 0;
`

const ValeurContent = styled.div`
    margin: 0 30px;
    
`
const ValeurContentBis = styled.div`
    margin-top: 10px;
    
`

const Img = styled.img`
    width: 100%;
    height: auto;
`

const Picto = styled.img`
    width: 25px;
    height: auto;
`

const ImgService = styled.img`
  max-width: 312px;
  height: auto;
`
const SectionServices = styled.section`
  margin-bottom: 20px;

`

const Presentation = () => (
    <div className="row">
        <Helmet>
            <title>Présentation || La Conciergeries des Petits</title>
            <meta name="keywords" content="HTML,CSS,JavaScript"/>
            <meta name="author" content="Adrien Gillmann"/>
            <meta
                name="description"
                content="Présentation du concepte de la Conciergeries des Petits"  
            />
        </Helmet>
        <Header
            background={colors.primary_alt}
            textColor={colors.white}
            logoColor="blanc"
        />
        <TopContent className="col-xs-12">
            <div className="row">
                {/* <EnConstruction/> */}
                <div className="col-md-offset-2 col-md-8 col-xs-12">
                    {/* <Title className="center-xs">La Conciergerie des Petits</Title> */}
                    <SubTitle className="start-md center-xs">La Conciergerie des Petits c'est avant tout une conciergerie haut de gamme dédiée aux enfants de tout âge.</SubTitle>
                    <Paragraphe>Le métier de parents est certainement aujourd'hui un des plus difficiles au monde. Pour nos petits chéris, nous voulons tout simplement le meilleur, ce qui nous oblige à endosser le costume de "superhéros" de l'organisation en jonglant avec nos obligations et contraintes tant personnelles que professionnelles.</Paragraphe>
                    <Paragraphe>La conciergerie de Petits vous propose un multitude de services et prestations dédiés aux enfants avec le but d'assurer leur développement intellectuel, émotionnel, culturel, sensoriel et manuel mais aussi de faciliter le quotidien des parents !</Paragraphe>
                    <Paragraphe>Notre équipe se compose de professionnels, chacun expert dans leur domaine mais travaillant tous mains dans la main avec des pédo-psychologues qui proposent, via des approches innovantes et ludiques, d'accompagner votre enfant à révéler tout son potentiel !</Paragraphe>
                </div>
            </div>
        </TopContent>
        <div className="col-xs-12">
            <SubTitleBis className="center-xs">La Conciergerie des petits à votre service</SubTitleBis>
            <div className="row">
                <div className="col-md-offset-1-and-half col-md-3 col-xs-12">
                    <SerciveContent>
                        <SubTitleAlt className="center-md">Disponible 7j/7</SubTitleAlt>
                        <Paragraphe>Une fois membre vous obtenez le numéro personnel de votre concierge que vous pouvez contacter à tout moment par téléphone, sms et même WhatsApp.</Paragraphe>
                    </SerciveContent>
                </div>
                <div className="col-md-3 col-xs-12">
                    <SerciveContent>
                        <SubTitleAlt className="center-md">A votre écoute</SubTitleAlt>
                        <Paragraphe>Vous n'avez qu'à lui confier vos besoins et envies et il se mettra à votre disposition pour vous satisfaire dans les meilleurs délais.</Paragraphe>
                    </SerciveContent>
                    
                </div>
                <div className="col-md-3 col-xs-12">
                    <SerciveContent>
                        <SubTitleAlt className="center-md">Inventif et professionnel</SubTitleAlt>
                        <Paragraphe>La Conciergerie des petits met à votre service son expérience, son inventivité et ses meilleurs prestataires pour vos idées les plus folles.</Paragraphe>
                    </SerciveContent>
                </div>
            </div>
        </div>
        <div className="col-xs-12">
            <div className="row">
                <div className="col-md-offset-1-and-half col-md-6 col-xs-12">
                    <ValeurContent>
                        <SubTitleBis>Nos missions et valeurs</SubTitleBis>
                        <Img src="https://res.cloudinary.com/bomzie/image/upload/v1560412295/conciergerie/img/shutterstock_1232576995_vbcjji.jpg" alt="Photo"/>
                        <ValeurContentBis className="row middle-xs">
                            <Picto className="col-xs-2" src="https://res.cloudinary.com/bomzie/image/upload/v1559290630/conciergerie/picto/heart_rn8gpw.svg" alt="picto"/>
                            <Paragraphe className="col-xs-10  middle-xs">Transmission, entre aide, créativité, soutien, écoute, respect, confiance et bienveillance forment l'ADN de la conciergerie des petits.</Paragraphe>
                        </ValeurContentBis>
                        <ValeurContentBis className="row middle-xs">
                            <Picto className="col-xs-2" src="https://res.cloudinary.com/bomzie/image/upload/v1559290630/conciergerie/picto/mortarboard_p75cqg.svg" alt="picto"/>
                            <Paragraphe className="col-xs-10  middle-xs">Nous exigeons des garanties et engagements de la part des intervenants : tous les diplômes sont vérifiés.</Paragraphe>
                        </ValeurContentBis>
                        <ValeurContentBis className="row middle-xs">
                            <Picto className="col-xs-2" src="https://res.cloudinary.com/bomzie/image/upload/v1559290630/conciergerie/picto/contract_xr8ztq.svg" alt="picto"/>
                            <Paragraphe className="col-xs-10  middle-xs">Notre charte de qualité est  signée par tous.</Paragraphe>
                        </ValeurContentBis>
                        <ValeurContentBis className="row middle-xs">
                            <Picto className="col-xs-2" src="https://res.cloudinary.com/bomzie/image/upload/v1559290630/conciergerie/picto/luggage_yelyiy.svg" alt="picto"/>
                            <Paragraphe className="col-xs-10  middle-xs">Nous évaluons régulièrement notre équipe pour nous assurer de leur professionnalisme.</Paragraphe>
                        </ValeurContentBis>
                    </ValeurContent>
                </div>
                <div className="col-md-3">
                    <ValeurContent>
                        <SubTitleBis>Un brin d'histoire</SubTitleBis>
                        <Paragraphe>La Conciergerie des Petits c’est l’histoire de deux mamans, deux sœurs Sarah et Jessica, respectivement 2 et 4 enfants. Femmes actives et entrepreneuses, ces deux mères sont comme beaucoup confrontées à l’enfer de l’organisation entre leurs contraintes professionnelles et celles liées au planning de leurs enfants !! Des sorties d’écoles, aux activités extra-scolaires, les cours d’anglais, le foot, la danse, la leçon de piano, en passant par l’organisation de l’anniversaire du petit dernier, le rendez vous chez le médecin … les courses pour la maison, le boulot… Ahaha !!! Comment faire pour tout gérer en même temps et faire plaisir à tout le monde ??!! </Paragraphe>
                        <Paragraphe>C’est de ce casse tête, qu’est née La conciergerie des Petits. Une conciergerie haut de gamme dédiée aux enfants qui se veut être une véritable boite à outils pour les parents dans laquelle chacun peut trouver tout ce dont il a besoin pour son bambin. La Conciergerie des Petits c’est simplifier la vie des parents tout en accompagnant les enfants à se développer et à révéler leur potentiel en s’amusant !</Paragraphe>
                    </ValeurContent>
                </div>
            </div>
        </div>
        <div className="col-xs-12">
            <SubTitleBis className="center-xs">Un aperçu de notre équipe</SubTitleBis>
            <div className="row">
                <div className="col-xs-offset-1 col-xs-10">
                    <Slider/>
                </div>
            </div>
        </div>
        {/* Services */}
        <SectionServices className="col-sm-12 center-xs">
            <div className="row">
                <div className="col-xs-12">
                    <SubTitleBis>Découvrez à présent nos services</SubTitleBis>
                </div>
                <SerciveContentBis className="col-md-4 col-sm-6 col-xs-12">
                    <Link to="/services-petite-enfance" style={{ textDecoration: 'none'}}>
                        <ImgService src="https://res.cloudinary.com/elbitmedias/image/upload/v1557151216/u3onmznfi5i9mvhxajma.jpg" alt="Illustration"/>
                    </Link>
                    <SubTitleAlt>Services <span style={{display: "block"}}>petite enfance</span></SubTitleAlt>
                    <Link to="/services-petite-enfance" style={{ textDecoration: 'none'}}>
                        <Button
                            color={colors.white}
                            background={colors.primary_alt}
                            border={"none"}
                            text="Je découvre"
                        />
                    </Link>
                </SerciveContentBis>
                <SerciveContentBis className="col-md-4 col-sm-6 col-xs-12">
                    <Link to="/services-enfance" style={{ textDecoration: 'none'}}>
                        <ImgService src="https://res.cloudinary.com/elbitmedias/image/upload/v1557151362/oafptwdhdkx7fasqvtwg.jpg" alt="Illustration"/>
                    </Link> 
                    <SubTitleAlt>Services <span style={{display: "block"}}>enfance</span></SubTitleAlt>
                    <Link to="/services-enfance" style={{ textDecoration: 'none'}}>
                        <Button
                            color={colors.white}
                            background={colors.primary_alt}
                            border={"none"}
                            text="Je découvre"
                        />
                    </Link>        
                </SerciveContentBis>
                <SerciveContentBis className="col-md-4 col-sm-12 col-xs-12">
                    <Link to="/services-aux-parents" style={{ textDecoration: 'none'}}>
                        <ImgService src="https://res.cloudinary.com/elbitmedias/image/upload/v1557152492/biaruo2kaj4swxkehnml.jpg" alt="Illustration"/>
                    </Link>
                    <SubTitleAlt>Services <span style={{display: "block"}}>aux parents</span></SubTitleAlt>
                    <Link to="/services-aux-parents" style={{ textDecoration: 'none'}}>
                        <Button
                            color={colors.white}
                            background={colors.primary_alt}
                            border={"none"}
                            text="Je découvre" 
                        />
                    </Link>
                </SerciveContentBis>
            </div>
        </SectionServices>
    <Information/>
    <Footer/>
    </div>
);

export default Presentation;