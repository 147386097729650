import styled from '@emotion/styled';

import colors from '../../../helpers/colors';

export default styled.div`
    h1 {
      color: ${colors.black};
      font-family: 'Avenir';
      font-weight: 900;
      font-size: 2.5em;
      margin-top: 250px;
      @media (max-width: 1023px){
        margin-top: 50px;
      }
      & > span {
        font-weight: 300;
      }
    }
    h2 {
        color: ${colors.black};
        font-family: 'Avenir';
        font-weight: 600;
        font-size: 2em;
    }
    h3 {
        color: ${colors.black};
        font-family: 'Avenir';
        font-weight: 300;
        font-size: 1.5em;
    }

    p {
      color: ${colors.black};
      font-family: 'Avenir';
      font-weight: 300;
      font-size: 1.2em;
      margin: 30px 0px;
    }
    
    button {
      color: ${colors.white};
      background: ${colors.primary};
      border: none;
      border-radius: 34px;
      padding: 13px 23px;
      width: 70%; 
      box-shadow: 0 1px 2px rgba(0,0,0,0.15);
      transition: 0.33s;
      cursor:pointer;
      
      &:after {
        box-shadow: 0 1px 2px rgba(0,0,0,0.15);
      }
      &:hover {
        box-shadow: 0 4px 9px rgba(0,0,0,0.3);
        position:relative;
        top: -2px;
      }
      & > span {
        font-family: 'Avenir';
        font-weight: 700;
        letter-spacing: 0.1em;
        font-size: 1em;
        color: ${colors.white};
      }
      
`