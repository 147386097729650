import React, { Component } from 'react';
import Developpement from './Developpement';
import api from '../../../../../helpers/api';

class DeveloppementContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataDeveloppement: [],
        };
    }

    async componentDidMount(){
        window.scrollTo(0, 0);
        const dataDeveloppement = await api.getServiceEnfance("developpement_enfant");

        this.setState({
            dataDeveloppement: dataDeveloppement.services,
        })
        console.log("state:",this.state);
    }
    render() {
        return (
            <Developpement
                dataDeveloppement={this.state.dataDeveloppement}
            />
        );
    }
}

export default DeveloppementContainer;
