// Lib React
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
// Components
import Header from '../../Header/HeaderContainer';
import Footer from '../../Footer/FooterContainer';
import Button from '../../Button/ButtonContainer';
import Information from '../Sections/Information/Information';
import SliderStage from '../../Slider/SliderStage';

// Lib Styles
import styled from '@emotion/styled';
import colors from '../../../helpers/colors';
//import EnConstruction from '../../EnConstruction';

//////////////////// STYLES
const SectionMain = styled.section`
  margin-top: 160px;
  @media (max-width: 1023px){
    margin-top: 0px;
  }
`
const SectionReserve = styled.section`
    margin-top: 10px;
    margin-bottom: 30px;

`

const SectionProgramme = styled.section`

`

const SectionPhotos = styled.section`
  margin: 10px 0;
`

const SectionPrix = styled.section`
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 30px 0;
  background: ${colors.white_alt};
`

const SectionInfo = styled.section`
  margin-top: 10px;
  margin-bottom: 20px;  
`
const SectionLieu = styled.section`
  margin-bottom: 20px;  
`

////////// TYPO

const Title = styled.h1`
  color: ${colors.primary};
  font-family: 'Avenir';
  font-weight: 700;
  font-size: 2rem;
  margin: 10px 0px;
`
const TitleBis = styled.h2`
  color: ${colors.primary};
  font-family: 'Avenir';
  font-weight: 700;
  font-size: 2rem;
  margin: 20px 0;
`
const SubTitle = styled.h3`
  color: ${colors.primary_alt};
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 2rem;
  margin: 30px 0px;
`
const Paragraphe = styled.p`
  color: ${colors.black};
  text-align: justify;
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 1.3rem;
`
const ParagrapheBis = styled.p`
  color: ${colors.black};
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 1.2rem;
  & > span {
    font-size: 1.3rem;
  }
`
const Italic = styled.p`
  color: ${colors.black};
  font-family: 'Avenir';
  font-weight: 500;
  font-style: italic;
  font-size: 1rem;
`
const TextList = styled.li`
    color: ${colors.black};
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
`
const Img = styled.img`
    width: auto;
    @media (max-width: 1023px){
      height: 40vh;
    }
`

//////////////////// VIEW

const Abonnement = () => (
  <div className="row">
    <Helmet>
        <title>Stages vacances scolaires || La Conciergeries des Petits</title>
        <meta name="keywords" content="HTML,CSS,JavaScript"/>
        <meta name="author" content="Adrien Gillmann"/>
        <meta
            name="description"
            content="Les stages vacances scolaires proposé par la Conciergeries des Petits"  
        />
    </Helmet>
    <Header
      background={colors.primary_alt}
      textColor={colors.white}
      logoColor="blanc"
    />
    <SectionMain className="col-md-offset-1 col-md-10 col-sm-12">
      <div className="row">
        {/* <EnConstruction/> */}
        <Title className="col-xs-12 center-xs">Vous cherchez à occuper vos enfants pendant les vacances scolaires ?</Title>
        <TitleBis className="col-xs-12 center-xs">La conciergerie des Petits s'occupe de tout.</TitleBis>
        <Paragraphe className="col-md-offset-1 col-md-10 col-xs-12 start-xs">Pendant une semaine vos enfants vont s’amuser en groupe en faisant plus de 10 ateliers ludiques et créatives différentes : musique, chant, danse, magie, jardinage, théâtre, arts plastiques, scientifique, cuisine, kapla, lego, puzzle. Nous vous proposons également de venir chercher votre bambin à la maison le matin et de le déposer à la fin de chaque journée.</Paragraphe>
      </div>
    </SectionMain>
    <SectionReserve className="col-xs-12">
      <div className="row center-xs">
        <TitleBis style={{margin: 0}} className="col-xs-12">Réservez dès à présent</TitleBis>
        <Italic className="col-xs-12">Nombre de place limité</Italic>
        <Link to="/contact" style={{ textDecoration: 'none'}}>
          <Button
            color={colors.white}
            background={colors.black}
            border={"none"}
            text="Contactez-nous"
            className="col-xs-12" 
          />
        </Link>
      </div>
    </SectionReserve>
    <SectionProgramme className="col-xs-12">
      <div className="row center-xs">
        <TitleBis style={{marginBottom: 35}} className="col-xs-12">Découvrez notre programme</TitleBis>
        <Img className="col-md-6 col-xs-12 offset-md-2"src="https://res.cloudinary.com/gillmann/image/upload/v1570574116/Conciergerie/planning-conciergerie-octobre.jpg" alt="Programme"/>
      </div>
    </SectionProgramme>
    <SectionPhotos className="col-xs-12">
      <div className="row center-xs">
        <SubTitle style={{marginBottom: 35}} className="col-xs-10 start-sm center-xs">Photos de stages</SubTitle>
        <div className="col-xs-10">
          <SliderStage/>
        </div>
      </div>
    </SectionPhotos>
    <SectionPrix className="col-xs-12">
      <div className="row center-xs">
        <TitleBis className="col-xs-12 center-xs">Nos tarifs</TitleBis>
      </div>
      <div className="row center-xs">
          <ParagrapheBis className="col-xs-3">Tarif sans transport <span>550 €</span></ParagrapheBis>
          <ParagrapheBis className="col-xs-3">Tarif avec transport <span>sur devis</span></ParagrapheBis>
        </div>
    </SectionPrix>
    <SectionInfo className="col-md-offset-1 col-md-10 col-sm-12">
      <div className="row">
        <SubTitle className="col-xs-12 start-sm center-xs">Informations pratiques :</SubTitle>
        <ul>
          <TextList>Horaire du lundi au vendredi : 10h - 17h </TextList>
          <TextList>Prévoir une lunch box pour chaque enfant comprenant une collation pour le goûter</TextList>
        </ul>
      </div>
    </SectionInfo >
    <SectionLieu className="col-md-offset-1 col-md-10 col-sm-12">
      <div className="row">
        <SubTitle className="col-xs-12 start-sm center-xs">Lieu de l'activité</SubTitle>
        <iframe
          title="Google map"
          className="col-xs-12"
          width="600"
          height="450"
          frameBorder="0" style={{border:0}}
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAWOsFDveYVRJ7dnVc0GaWaVyJGkJNM_G0&q=Paris" allowFullScreen>
        </iframe>
      </div>
    </SectionLieu>
    <SectionReserve className="col-xs-12">
      <div className="row center-xs">
        <TitleBis style={{margin: 0}} className="col-xs-12">Réservez dès à présent</TitleBis>
        <Italic className="col-xs-12">Nombre de place limité</Italic>
        <Link to="/contact" style={{ textDecoration: 'none'}}>
          <Button
            color={colors.white}
            background={colors.black}
            border={"none"}
            text="Contactez-nous"
            className="col-xs-12" 
          />
        </Link>
      </div>
    </SectionReserve>

    <Information/>
    <Footer/>

  </div>
);

export default Abonnement;
