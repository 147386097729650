import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import AbonnementCarte from './AbonnementCarte';
import AbonnementInfinite from './AbonnementInfiniteContainer';
// import AbonnementPlatinium from './AbonnementPlatinium';

import Button from '../../Button/ButtonContainer';

import styled from '@emotion/styled';
import colors from '../../../helpers/colors';

const AbonnementContent = styled.div`
    padding: 20px 0px;
    margin: 10px;
    max-width: 450px;
    background: ${props => props.background};
    border-radius: 6px;
    border: ${props => props.border};
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
`

class AbonnementContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: ""
        };
    }
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className="row">
                
                <div className="row" style={{justifyContent: "space-around"}}>
                <AbonnementContent className="col-lg-4 col-md-6" background={colors.white} border={"1px solid " + colors.primary_alt}>
                    <AbonnementCarte/>
                    <Link to="/register" style={{ textDecoration: 'none'}}>
                        <Button 
                            color={colors.primary_alt}
                            background={colors.white}
                            border={"1px solid " + colors.primary_alt}
                            text="À la carte"
                        />
                    </Link> 
                </AbonnementContent>
                <AbonnementContent className="col-lg-4 col-md-6" background={colors.primary_bis} border={"none"}>
                    <AbonnementInfinite/>
                    <Link to="/register?statut=Infinite" style={{ textDecoration: 'none'}}>
                        <Button 
                            color={colors.white}
                            background={colors.primary_alt}
                            border={"none"}
                            text="Devenir Infinite"
                        />
                    </Link> 
                </AbonnementContent>
                {/* <AbonnementContent className="col-lg-3 col-md-12" background={colors.primary_alt} border={"none"}>
                    <AbonnementPlatinium/>
                    <Link to="/register?statut=Platinium" style={{ textDecoration: 'none'}}>
                        <Button 
                            color={colors.white}
                            background={colors.primary}
                            border={"none"}
                            text="Devenir Platinium"
                        />
                    </Link> 
                </AbonnementContent> */}
            
                </div>
            </div>
        );
    }
}

export default AbonnementContainer;