import React from 'react';
import styled from '@emotion/styled';

const TextCard = styled.p`
    font-family: 'Avenir Black';
    font-weight: 900;
    font-size: 1.5em;
    margin 20px 0;
`

const TextCardBis = styled.p`
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 1.1em;
    margin: 10px 0;
`

const Img = styled.img`
    width: 100%;
    height: auto;
`

const CardContent = styled.div`
    padding: 10px 30px;
`

const CardEquipe = ({data, photo, nom, prenom, profession}) => (
  <div className="row">
    <CardContent className="col-xs-12">
        <Img src={photo}/>
        <TextCard>{nom} {prenom}</TextCard>
        <TextCardBis>{profession}</TextCardBis>
    </CardContent>
  </div>
);

export default CardEquipe;
