import React, { Component } from 'react';

import Presentation from './Presentation';

class PresentationContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };
    }
    componentDidMount(){
        window.scrollTo(0,0);
    }
    render() {
        return (
            <Presentation/>
        );
    }
}

export default PresentationContainer;
