import React, { Component } from 'react';
import Home from './Home';
import api from '../../../helpers/api';

class HomeContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: ""
        };
    }
    async componentDidMount(){
        window.scrollTo(0, 0);
        const data = await api.getPromotion();
        this.setState({data: data[0]})
    }
    render() {
        console.log(this.state.data);
        return (
            <Home
                data={this.state.data}
            />
        );
    }
}

export default HomeContainer;
