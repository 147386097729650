import React, { Component } from 'react';
import Footer from './Footer';

class FooterContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            test: "test"
        };
    }

    render() {
        return (
            <Footer/>
        );
    }
}

export default FooterContainer;