import React from 'react';
import { Link } from 'react-router-dom'

import styled from '@emotion/styled';
import colors from '../../helpers/colors';
//import EnConstruction from '../EnConstruction';

const MiddleHeader = styled.div`
  margin: 5px 0px 5px;
  z-index:2;
  transition: all 0.5s ease-out;
  @media (max-width: 1023px) {
    display: none;
  }
`
const DropdownContent = styled.div`
  position: absolute;
  background-color: #f9f9f9;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
  & > a {
      color: ${colors.black};
      padding: 40px;
      text-decoration: none;
  }
`

const Dropdown = styled.div`
  position: relative;
  &:hover .dropdown-content {
    display: block;
    visibility: visible;
    opacity: 1;
  }
`
const TextLink = styled.h2`
  color: ${props => props.textColor};
  font-family: 'Avenir';
  font-weight: 300;
  font-size: 1em;
   &:hover {
    font-style: bold;
  }
`
const TextLinkD = styled.h2`
  color: ${props => props.textColor};
  font-family: 'Avenir';
  font-weight: 300;
  padding: 20px 0;
  font-size: 1em;
   &:hover {
    font-style: bold;
  }
`

const Header = ({textColor}) => (
  
    <MiddleHeader className="row scroll-header middle-header">
      <Link className="col-sm-2 center-sm" to="/presentation" style={{ textDecoration: 'none'}}> 
        <TextLink textColor={textColor}>Présentation</TextLink>
      </Link>
      <Dropdown className="col-sm-2 center-sm">
        <Link className="" to="/services-petite-enfance" style={{ textDecoration: 'none'}}> 
          <TextLink textColor={textColor}>Services petite enfance
          </TextLink>
        </Link>
          <DropdownContent className="dropdown-content">
            <Link to="/services-petite-enfance/bien-etre" style={{ textDecoration: 'none', padding: 0}}> 
              <TextLinkD className="center-sm">Bien-être bébé</TextLinkD>
            </Link>
            <Link to="/services-petite-enfance/accompagnement-maman" style={{ textDecoration: 'none', padding: 0}}>
              <TextLinkD className="center-sm">Accompagnement maman</TextLinkD>
            </Link>
          </DropdownContent>
      </Dropdown>
      <Dropdown className="col-sm-2 center-sm">
        <Link to="/services-enfance" style={{ textDecoration: 'none'}}> 
          <TextLink textColor={textColor}>Services enfance
          </TextLink>
          </Link>
          <DropdownContent className="dropdown-content">
              <Link to="/services-enfance/educatives" style={{ textDecoration: 'none', padding: 0}}> 
                <TextLinkD className="center-sm">Activités éducatives</TextLinkD>
              </Link>
              <Link to="/services-enfance/sportives" style={{ textDecoration: 'none', padding: 0}}>
                <TextLinkD className="center-sm">Activités sportives</TextLinkD>
              </Link>
              <Link to="/services-enfance/ludiques-creatives" style={{ textDecoration: 'none', padding: 0}}>
                <TextLinkD className="center-sm">Activités ludiques & créatives</TextLinkD>
              </Link>
              <Link to="/services-enfance/developpement-enfant" style={{ textDecoration: 'none', padding: 0}}>
                <TextLinkD className="center-sm">Développement de l'enfant</TextLinkD>
              </Link>
            </DropdownContent>
        
      </Dropdown>
      <Link className="col-sm-2 center-sm" to="/services-aux-parents" style={{ textDecoration: 'none'}} > 
        <TextLink textColor={textColor}>Services aux parents
        </TextLink>
      </Link>
      <Link className="col-sm-2 center-sm" to="/stages-vacances-scolaires" style={{ textDecoration: 'none'}}> 
        <TextLink textColor={textColor}>Stages vacances scolaires</TextLink>
      </Link>
      <Link className="col-sm-2 center-sm" to="/abonnement" style={{ textDecoration: 'none'}}> 
        <TextLink textColor={textColor}>Abonnement</TextLink>
      </Link>
      {/* <EnConstruction/> */}
    </MiddleHeader>
    
);

export default Header;
