import React from 'react';
import { Helmet } from 'react-helmet';

import Header from '../../../Header/HeaderContainer';
import CardService from '../../../Cards/CardService/CardServiceProtoContainer';
import Information from '../../Sections/Information/Information';
import Footer from '../../../Footer/FooterContainer';

import styled from '@emotion/styled';
import colors from '../../../../helpers/colors';
import StagePub from '../../Sections/StagePub/StagePub';
//import EnConstruction from '../../../EnConstruction';

// const Title = styled.h1`
//     color: ${colors.primary};
//     font-family: 'Avenir';
//     font-weight: 900;
//     font-size: 2.2em;
//     margin-bottom: 0;
//     & > span {
//         font-weight: 500;
//         font-size: 0.85em;
//     }
// `

// const TitleContent = styled.div`
//     margin-top: 150px;
// `

const SectionTitle = styled.h3`
    color: ${colors.primary_alt};
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 1.8em;
    margin-bottom: 0;
`

const ServiceContent = styled.div`
    margin-top: 150px;
    margin-bottom: 40px;
    @media (max-width: 1023px){
        margin-top: 40px;
    }
`

const ServiceEnfance = ({sectionFilter,dataEducative,dataSportive,dataLudique,dataDeveloppement}) => (
    <div className="row">
        <Helmet>
            <title>Services enfance || La Conciergeries des Petits</title>
            <meta name="keywords" content="HTML,CSS,JavaScript"/>
            <meta name="author" content="Adrien Gillmann"/>
            <meta
                name="description"
                content="Les services destinés à l'enfance de 2 à 12 ans proposé par la Conciergeries des Petits"  
            />
        </Helmet>
        <Header
            background={colors.primary_alt}
            textColor={colors.white}
            logoColor="blanc"
        />
        {/* <TitleContent className="col-xs-12">
            <div className="row">
                <Title className="col-xs-offset-1 col-xs-10 start-sm center-xs">Services Enfance : <span>Pour les bambins de 2 à 12 ans</span></Title>
            </div>
        </TitleContent> */}
        <ServiceContent className="col-xs-12">
            <section className="row" id="educatives">
                {/* <EnConstruction/> */}
                <div className="col-md-offset-1 col-md-10 col-xs-12">
                    <div className="row">
                        <div className="col-md-3 col-sm-6 col-xs-12" >
                            <SectionTitle className="center-xs">Activités <br/>éducatives</SectionTitle>
                            <CardService
                                link={"/services-enfance/educatives"}
                                name={"Soutien scolaire"}
                                description={"Voir tout les activités éducatives"}
                                illustration={"https://res.cloudinary.com/elbitmedias/image/upload/v1557392681/qjt7xpexpqqizhwqmiw1.jpg"}
                            />
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12" >
                            <SectionTitle className="center-xs">Activités <br/>sportives</SectionTitle>
                            <CardService
                                link={"/services-enfance/sportives"}
                                description={"Voir tout les activités sportives"}
                                illustration={"https://res.cloudinary.com/elbitmedias/image/upload/v1557151362/oafptwdhdkx7fasqvtwg.jpg"}
                            />
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12" >
                            <SectionTitle className="center-xs">Activités ludiques <br/>& créatives</SectionTitle>
                            <CardService
                                link={"/services-enfance/ludiques-creatives"}
                                description={"Voir tout les activités ludiques & créatives"}
                                illustration={"https://res.cloudinary.com/elbitmedias/image/upload/v1557395722/w6dcn8ngsx3bn1x9qjwr.jpg"}
                            />
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12" >
                            <SectionTitle className="center-xs">Développement <br/>de l'enfant</SectionTitle>
                            <CardService
                                link={"/services-enfance/developpement-enfant"}
                                name={"Groupe de Parole"}
                                description={"Voir tout les activités pour le développement de l'enfant"}
                                capacite={5}
                                duree={"1h"}
                                prix_m={"50€"}
                                prix_p={"60€"}
                                illustration={"https://res.cloudinary.com/elbitmedias/image/upload/v1557395994/c1at41ca9jdxe0qjfsmi.jpg"}
                            />
                        </div>
                    </div>
                </div>      
            </section>
        </ServiceContent>
        <StagePub/>
        <Information/>
        <Footer/>
    </div>
    
);

export default ServiceEnfance;
