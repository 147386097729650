import React, { Component } from 'react';
import CardService from './CardService';

class CardServiceContainer extends Component {
    render() {
        // const data = require("./servicesData.json");
        // const services = data.services;
        //console.log(services.services_petite_enfance.accompagnement_des_mamans[0]);
        return (
            <CardService
                id={this.props.id}
                name={this.props.name}
                description={this.props.description}
                capacite={this.props.capacite}
                duree={this.props.duree}
                prix_m={this.props.prix_m}
                prix_p={this.props.prix_p}
                illustration={this.props.illustration}
            />
        );
    }
}

export default CardServiceContainer;
