import React from 'react';
import styled from '@emotion/styled';

const ButtonStyle = styled.button`
  color: ${props => props.color};
  border-radius: 34px;
  padding: 13px 23px;
  border: ${props => props.border};
  background: ${props => props.background};
  transition: 0.33s;
  
  /* Transition to showing the bigger shadow on hover */
  &:hover {
    position:relative;
    top: -2px;
  }
  cursor:pointer;
  @media (max-width: 1023){
    font-weight: 500;
    font-size: 0.85rem;
  }
`

const TextButton = styled.span`
  font-family: 'Avenir';
  font-weight: 700;
  letter-spacing: 0.1rem;
  font-size: 1rem;
  @media (max-width: 1023){
    font-weight: 500;
    font-size: 0.85rem;
  }
`

const Button = ({text, color, background, border}) => (
  <div className="button-info">
    <ButtonStyle 
      color={color} 
      background={background} 
      border={border}
    >
      <TextButton>{text}</TextButton>
    </ButtonStyle>
  </div>
);

export default Button;
