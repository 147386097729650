import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Home from "./Templates/Home/HomeContainer";
import Presentation from './Templates/Presentation/PresentationContainer';

import ServicePEnfance from './Templates/Services/ServicePEnfance/ServicePEnfanceContainer';
import BienEtre from './Templates/Services/ServicePEnfance/BienEtre/BienEtreContainer';
// import Journee from './Templates/Services/ServicePEnfance/Journee/JourneeContainer';
import Accompagnement from './Templates/Services/ServicePEnfance/Accompagnement/AccompagnementContainer';

import ServiceEnfance from './Templates/Services/ServiceEnfance/ServiceEnfanceContainer';
import Educative from './Templates/Services/ServiceEnfance/Educative/EducativeContainer';
import Sportive from './Templates/Services/ServiceEnfance/Sportive/SportiveContainer';
import Ludique from './Templates/Services/ServiceEnfance/Ludique/LudiqueContainer';
import Developpement from './Templates/Services/ServiceEnfance/Developpement/DeveloppementContainer';

import ServiceParent from './Templates/Services/ServiceParent/ServiceParentContainer';
import InfoService from './Templates/InfoService/InfoServiceContainer';
import Abonnement from './Templates/Abonnement/AbonnementContainer';
import Stage from './Templates/Stage/StageContainer';
import Register from './Templates/Authentification/Register/Register';
import Profile from './Templates/Profile/Profile';
import Login from './Templates/Authentification/Login/Login';
import Logout from './Templates/Authentification/Logout/Logout';
import Page404 from './Templates/Page404/Page404Container';
import Contact from './Templates/Contact/Contact';
import ForgotPass from './Templates/Authentification/ForgotPass/ForgotPass';
import ResetPass from './Templates/Authentification/ResetPass/ResetPass';
import PrivateRoute from './PrivateRoute';
import ConfirmEmail from './Templates/Authentification/ConfirmEmail/ConfirmEmail';
import ModifProfil from './Templates/Profile/ModifProfil';
import StripeTest from './Templates/StripTest/StripeTest';


class Router extends Component {
  constructor(props) {
      super(props);
      this.state = {
          data: ""
      };
  }

  render() {
      return (
        <BrowserRouter>
        <LastLocationProvider>
          <Route render={({location}) => (
            <TransitionGroup>
              <CSSTransition
                  key={location.key}
                  timeout={500}
                  classNames="fade"
                >
                <Switch> 
                  <Route path="/" component={Home} exact />
                  <Route path="/presentation" component={Presentation} exact />

                  <Route path="/services-petite-enfance/bien-etre" component={BienEtre}/>
                  {/* <Route path="/services-petite-enfance/journee-bebe" component={Journee}/> */}
                  <Route path="/services-petite-enfance/accompagnement-maman" component={Accompagnement}/>
                  <Route path="/services-petite-enfance" component={ServicePEnfance}/>

                  <Route path="/services-enfance/educatives" component={Educative}/>
                  <Route path="/services-enfance/sportives" component={Sportive}/>
                  <Route path="/services-enfance/ludiques-creatives" component={Ludique}/>
                  <Route path="/services-enfance/developpement-enfant" component={Developpement}/>
                  <Route path="/services-enfance" component={ServiceEnfance}/>

                  <Route path="/services-aux-parents" component={ServiceParent}/>
                  <Route path="/info-service/:id" component={InfoService} />
                  <Route path="/abonnement" component={Abonnement} />
                  <Route path="/stages-vacances-scolaires" component={Stage}/>
                  <Route path="/contact" component={Contact}/>

                  <Route path="/login" component={Login}/>
                  <Route path="/logout" component={Logout}/>
                  <Route path="/register" component={Register}/>
                  <Route path="/forgot-password" component={ForgotPass}/>
                  <Route path="/reset-password/:code" component={ResetPass}/>
                  <Route path="/email-confirmation/" component={ConfirmEmail}/>
                  <PrivateRoute path='/profile' component={Profile}/>
                  <PrivateRoute path='/modification-info' component={ModifProfil}/>
                  
                  <Route path="/stripe" component={StripeTest}/>
                   
                  <Route component={Page404}/>
                </Switch>

              </CSSTransition>
            </TransitionGroup>
          )}/>  
        </LastLocationProvider>  
        </BrowserRouter>
      );
  }
}

export default Router;