import React, { Component } from 'react';
import AbonnementInfinite from './AbonnementInfinite';
import api from '../../../helpers/api'

class AbonnementInfiniteContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prixMois: "",
            prixAnnee: "",
        };
    }
    
    async componentDidMount(){
        const dataPrix = await api.getPrix();
        console.log("tesssssst :",dataPrix[0]);
        this.setState({
            prixMois: dataPrix[0].mois,
            prixAnnee: dataPrix[0].annee

        })
        console.log("state:",this.state);
    }
    render() {
        return (
            <AbonnementInfinite
                prixMois={this.state.prixMois}
                prixAnnee={this.state.prixAnnee}
            />
        );
    }
}

export default AbonnementInfiniteContainer;
