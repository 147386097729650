import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';

import styled from '@emotion/styled';
import colors from '../../helpers/colors';

const Img = styled.img`
  max-width: 250px;
  height: auto;
  margin-bottom: 40px;
`
const TextUser = styled.span`
    color: ${colors.primary};
    font-family: 'Avenir';
    font-weight: 300;
    font-size: 1em;
` 
const IconUser = styled.i`
    color: ${colors.primary};
    padding-right: 10px;
`
const ChevronDown = styled.i`
  padding-left: 15px;
  color: ${colors.primary};
`

class BurgerMenu extends Component {
  constructor(props) {
      super(props);
      this.state = {
          dataCli: [],
      };
  }
  render(){
    if (!localStorage.getItem('client_conciergerie')) {
      if(!localStorage.getItem('info_client')){
          return (
            <Menu slide left width={'90vw'}> 
                <Link id="home" className="menu-item" to="/"><Img src="https://res.cloudinary.com/bomzie/image/upload/v1555511970/conciergerie/logo/LOGO_laconciergeriedespetits_vert_osipxh.png" alt="logo"/></Link>
                <Link id="about" className="menu-item" to="/presentation">Présentation</Link>
                <Link id="contact" className="menu-item" to="/services-petite-enfance">Services petite enfance</Link>
                <Link id="contact" className="menu-item" to="/services-enfance">Services enfance</Link>
                <Link id="contact" className="menu-item" to="/services-aux-parents">Services aux parents</Link>
                <Link id="contact" className="menu-item" to="/stages-vacances-scolaires">Stages vacances scolaires</Link>
                <Link id="abonnement" className="menu-item" to="/abonnement">Abonnement</Link>
                <hr style={{border: "1px solid greylight"}}></hr>
                <Link id="contact" className="menu-item" to="/register">Devenir Membre</Link>
                <Link id="contact" className="menu-item" to="/login">Connexion</Link>
            </Menu>
          );
      }
  } else {
    const localData = JSON.parse(localStorage.getItem('client_conciergerie'));
    let username = localData.user.username;
    return(
      <Menu slide right>
          <Link id="home" className="menu-item" to="/"><Img src="https://res.cloudinary.com/bomzie/image/upload/v1555511970/conciergerie/logo/LOGO_laconciergeriedespetits_vert_osipxh.png" alt="logo"/></Link>
          <Link id="about" className="menu-item" to="/presentation">Présentation</Link>
          <Link id="contact" className="menu-item" to="/services-petite-enfance">Services petite enfance</Link>
          <Link id="contact" className="menu-item" to="/services-enfance">Services enfance</Link>
          <Link id="contact" className="menu-item" to="/services-aux-parents">Services aux parents</Link>
          <Link id="contact" className="menu-item" to="/stages-vacances-scolaires">Stages vacances scolaires</Link>
          <Link id="abonnement" className="menu-item" to="/abonnement">Abonnement</Link>
          <hr style={{border: "0.5px solid greylight"}}></hr>
          <Link className="menu-item" to="/profile" style={{ textDecoration: 'none'}}> 
              <IconUser className="far fa-user"></IconUser>
                  <TextUser>{username}</TextUser>
              <ChevronDown className="fas fa-chevron-down"></ChevronDown>
          </Link>
          <Link className="menu-item" to="/logout" style={{ textDecoration: 'none'}}>Déconnexion</Link>
      </Menu>
    )
  }
}
}
export default BurgerMenu;