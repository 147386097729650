import React from 'react';
import styled from '@emotion/styled';
import colors from '../../../helpers/colors';
import { Link } from 'react-router-dom';

const TextCard = styled.span`
    display: block;
    color: ${colors.black};
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 1.4rem;
    margin 20px 0;
    margin-bottom: 0px!important;
    @media (max-width: 1612px) {
        font-size: 1.3rem;
        font-weight: 500;
      }
    @media (max-width: 1023px) {
        font-size: 1.2em;
        font-weight: 500;
    }
`

const TextCardBis = styled.span`
    display: block;
    color: ${colors.black};
    font-family: 'Avenir';
    font-weight: 500;
    font-size: 1.2em;
    
    
    @media (max-width: 1612px) {
        font-size: 0.7em;
        font-weight: 500;
      }
    @media (max-width: 1023px) {
        font-size: 1.2em;
        font-weight: 500;
    }
    & > span {
        font-weight: 700;
    }
`

const Img = styled.img`
    width: 100%;
    height: auto;
`

const CardContent = styled.div`
    margin: 20px 10px;
    padding: 10px 30px 10px 30px;
`

const PrixContent = styled.div`
    display: inline;
    padding: 0 !important;
    margin-top: 20px;
`

const Prix = styled.span`
    font-family: 'Avenir';
    font-weight: 500;
    font-size: 1.2em;
    padding 5px 20px;
    background: ${colors.grey_bis};
    @media (max-width: 1612px) {
        font-size: 0.7em;
        font-weight: 500;
      }
    @media (max-width: 1023px) {
        font-size: 1.2em;
        font-weight: 500;
    }
`

const PrixBis = styled.span`
    font-family: 'Avenir';
    font-weight: 500;
    font-size: 1.2em;
    padding 5px 20px;
    @media (max-width: 1612px) {
        font-size: 0.7em;
        font-weight: 500;
      }
    @media (max-width: 1023px) {
        font-size: 1.2em;
        font-weight: 500;
    }
`

const HoverContent = styled.div`
    position: relative;
    &:hover .text{
        visibility: visible;
        opacity: 1;
    }
`

const PictoEye = styled.i`
    margin-right: 10px;
`

const Description = styled.div`
    position: absolute;
    width:100%;
    height:100%;
    top: 0px;
    background: ${colors.primary_alt};
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s, visibility .2s;
    
`

const TextDesc = styled.p`
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 1.2em;
    line-height: 30px;
    padding: 5px 20px;
    @media (max-width: 1612px) {
        font-size: 1em;
        line-height: 20px;
        font-weight: 300;
      }
    @media (max-width: 1262px) {
        font-size: 0.8em;
        line-height: 15px;
        font-weight: 300;
      }
    @media (max-width: 1023px) {
        font-size: 1.2em;
        line-height: 30px;
        font-weight: 300;
    }
`

const ButtonV = styled.button`
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 1em;
    margin: 20px 0px;
    color: ${colors.white};
    border-radius: 34px;
    padding: 10px 20px;
    border: none;
    background: ${colors.primary_alt};
    cursor:pointer;
`

const Info = styled.span`
    display:block;
    font-family: 'Avenir';
    font-weight: 300;
    font-size: 0.8em;
    color: ${colors.grey};
    margin-top: 20px;
`

const CardName = styled.div`
    min-height: 3em;
`

// const CardShadow = styled.div`

//     box-shadow: 0 1px 2px rgba(0,0,0,0.15);
//     transition: 0.23s;  
//     &:after {
//         box-shadow: 0 1px 2px rgba(0,0,0,0.15);
//     }
//     &:hover {
//         box-shadow: 0 4px 9px rgba(0,0,0,0.3);
//         position:relative;
//         top: -2px;
//     }

// `
const CardService = ({id, name, description, capacite, prix_m, prix_p, illustration, duree}) => (
  <div className="row" style={{padding: 10 }}>
    <CardContent className="col-xs-12">
        <Link to={`/info-service/${id}`} style={{ textDecoration: 'none'}}>
            <HoverContent className="overlay">
                <Img src={illustration}/>
                <Description className="text">
                    <TextDesc>{description.length > 230 ? (description.substr(0,230).concat('', '...')) : description}</TextDesc>
                </Description>
            </HoverContent>
        </Link>
        <CardName className="col-xs-12">
            <TextCard className="start-md center-xs ">{name}</TextCard>
        </CardName>
        <div className="row start-md center-xs">
            <div className="col-lg-6 col-xs-12">
                <TextCardBis>Capacité 0-{capacite} pers.</TextCardBis>
            </div>
            <div className="col-lg-6 col-xs-12">
                <TextCardBis>Durée : {duree}</TextCardBis>
            </div>
        </div>
        
        
        <div className="row middle-xs start-md center-xs">
            <PrixContent className="col-lg-6 col-xs-12">
                <Prix>{prix_m}* Membre</Prix>
            </PrixContent>
            <PrixContent className="col-lg-6 col-xs-12">
                <PrixBis>{prix_p}* Public</PrixBis>
            </PrixContent>
            <div className="col-xs-12">
                <Info>* par enfants</Info>
            </div>
        </div>
        <div className="row start-md center-xs">
            <Link className="col-md-12" to={`/info-service/${id}`} style={{ textDecoration: 'none'}}> {/* TODO: placer les id dans la redirection */}
                <ButtonV className="col-md-12" >
                    <PictoEye className="far fa-eye"/>
                    <span>Voir plus</span>
                </ButtonV>
            </Link>
        </div>
    </CardContent>
  </div>
);

export default CardService;
